import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import head_rest from "../../../../images/spare parts/single-needle/head-rest.jpg";

const HeadRestSN = () => {
  return (
    <div>
      <ProductPage
        image={head_rest}
        alt="head rest for sewing machine"
        title="Head Rest for Lockstitch Machine"
        price={4}
        del_price={10}
        discount={60}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Head Rest"
        Highlight_1="High Quality Material"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Head Rest for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Head Rest"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Material"
      />
    </div>
  )
}

export default HeadRestSN