import React from "react";
import SEO from "../../components/SEO";
import "../../styles/DomesticSewingMachine.css";
import Product from "../../components/Product";

// images
import u_1 from "../../images/Usha-hand/bandhan_1.jpg";
// import y_1 from "../../images/yamuna-black/y3-new.jpg";
import { Link } from "react-router-dom";

const DomesticSewingMachine = () => {
  return (
    <div className="machines">
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
      <SEO
      title="Sewing Machine - The Ultimate Sewing Companion - Effortless Stitching"
      description="The Sewing Machine is the perfect addition to your workshop. With its versatile and reliable design, this machine offers precision stitching and effortless controls for all skill levels. The ultimate sewing companion, it offers wide range of stitching options and durable design for long-lasting use."
      name="Sewix"
      type="website"
      url="https://www.sewix.in/domestic-sewing-machine"
      main_image={u_1}
      keywords="Home sewing machine, Domestic sewing machine, Portable sewing machine, Handheld sewing machine, Household sewing machine, Electric sewing machine, Mini sewing machine, Basic sewing machine, Beginner sewing machine, Desktop sewing machine, Tabletop sewing machine, Mechanical sewing machine, Computerized sewing machine, Automatic sewing machine, Sewing machine for home use, Lightweight sewing machine, Compact sewing machine, Simple sewing machine, Affordable sewing machine, Multi-purpose sewing machine, User-friendly sewing machine, Easy-to-use sewing machine, Sewing machine for beginners." />
      {/* <h4>Domestic Sewing Machine</h4> */}
      <Link
        to="/usha-bandhan-straight-stitch"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={u_1}
          title="Usha bandhan Straight Stitch"
          price={3899}
          del_price={4500}
          off={12}
        />
      </Link>
      {/* <Link
        to="/sewing-machine-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={y_1}
          title="Usha bandhan Straight Stitch"
          price={3999}
          del_price={4500}
          off={12}
        />
      </Link> */}
    </div>
  );
};

export default DomesticSewingMachine;
