import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import oil_window from "../../../../images/spare parts/single-needle/oil-window.jpg";

const OilWindowSN = () => {
  return (
    <div>
    <ProductPage
      image={oil_window}
      alt="sewing machine tension"
      title="Oil Window for Lockstitch Machine"
      price={5}
      del_price={10}
      discount={50}
      ruppee_icon="Cash On Delivery"
      delivery_icon="Fast delivery"
      trust_icon="Trusted Product"
      box_content="Oil Window"
      Highlight_1="High Quality Material"
      Highlight_2="Great for Industrial Use"
      Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
      Highlight_4="Imported Product"
      key_features="Oil Window for Industrial Lockstitch Single Needle Sewing Machine"
    />
    <SpecsSpares
      brand_name="Generic"
      type="Oil Window"
      compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
      material="High Quality Material"
    />
  </div>
  )
}

export default OilWindowSN