import React from "react";
import Product from "../../components/Product";
import "../../styles//categories/SparePartsCategory.css";
import { Link } from "react-router-dom";

// IMAGES
// import feed_dog from "../../images/spare parts/single-needle/juki-boot.jpg";
import feed_dog_12481r from "../../images/spare parts/single-needle/feed-dog-12481r.jpg";
import feed_dog_12482_309 from "../../images/spare parts/single-needle/feed-dog-12482-309.jpg";
import feed_dog_149057r from "../../images/spare parts/single-needle/feed-dog-149057r.jpg";
// import feed_dog_b1613_012_100 from "../../images/spare parts/single-needle/feed-dog-b1613-012-100.jpg";
import feed_dog_btype from "../../images/spare parts/single-needle/feed-dog-btype.jpg";
import feed_dog_etype from "../../images/spare parts/single-needle/feed-dog-etype.jpg";
import feed_dog_htype from "../../images/spare parts/single-needle/feed-dog-htype.jpg";
import feed_dog_ubt_etype from "../../images/spare parts/single-needle/feed-dog-ubt-etype.jpg";
import feed_dog_xdn18t from "../../images/spare parts/single-needle/feed-dog-xdn18t.jpg";

const FeedDogsCat = () => {
  return (
    <div className="spare-parts-categories">
      <Link
          to="/etype-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_etype}
            title="E-Type Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="E-Type Feed Dog"
            img_title="E-Type Feed Dog"
          />
        </Link>
        <Link
          to="/btype-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_btype}
            title="B-Type Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="B-Type Feed Dog"
            img_title="B-Type Feed Dog"
          />
        </Link>
        <Link
          to="/htype-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_htype}
            title="H-Type Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="H-Type Feed Dog"
            img_title="H-Type Feed Dog"
          />
        </Link>
        <Link
          to="/12481r-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_12481r}
            title="12481R Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="12481R Feed Dog"
            img_title="12481R Feed Dog"
          />
        </Link>
        <Link
          to="/12482-309-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_12482_309}
            title="12482-309 Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="needle plate"
            img_title="12482-309 Feed Dog"
          />
        </Link>
        <Link
          to="/149057r-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_149057r}
            title="149057R Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="149057R Feed Dog"
            img_title="149057R Feed Dog"
          />
        </Link>
        <Link
          to="/btype-ubt-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_ubt_etype}
            title="B-Type UBT Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="B-Type UBT Feed Dog"
            img_title="B-Type UBT Feed Dog"
          />
        </Link>
        <Link
          to="/xdn18t-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_xdn18t}
            title="XDN18T Feed Dog"
            price={17}
            del_price={30}
            off={50}
            alt="XDN18T Feed Dog"
            img_title="XDN18T Feed Dog"
          />
        </Link>
    </div>
  );
};

export default FeedDogsCat;
