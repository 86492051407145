import React from "react";
import ProductPage from "../../../../../components/ProductPage";
import SpecsSpares from "../../../../../components/SpecsSpares";
import btype from "../../../../../images/spare parts/single-needle/feed-dog-btype.jpg";

const FeedDogBType = () => {
  return (
    <div>
      <ProductPage
        image={btype}
        alt="sewing machine pressure foot"
        title="B-Type Pressure Foot for Lockstitch Machine"
        price={25}
        del_price={45}
        discount={43}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Pressure Foot"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Pressure Foot for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Pressure Foot"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  );
};

export default FeedDogBType;
