import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import bobbin from "../../../../images/spare parts/juki-781/bobbin.jpg";

const Bobbin781 = () => {
  return (
    <div>
    <ProductPage
      image={bobbin}
      alt="juki buttonhole tension"
      title="Juki Buttonhole Bobbin"
      price={40}
      del_price={70}
      discount={65}
      ruppee_icon="Cash On Delivery"
      delivery_icon="Fast delivery"
      trust_icon="Trusted Product"
      box_content="Bobbin"
      Highlight_1="High Quality Steel"
      Highlight_2="Great for Industrial Use"
      Highlight_3="Fit with Juki LBH-781, LBH-780, LBH-772"
      Highlight_4="Imported Product"
      key_features="Bobbin For Juki ButtonHole Sewing Machine"
    />
    <SpecsSpares
      brand_name="Generic"
      type="Bobbin"
      compatibility="Fit with Juki LBH-781, LBH-780, LBH-772"
      material="High Quality Steel"
    />
  </div>
  )
}

export default Bobbin781