import React from "react";
import "../styles/Footer.css";

// Material Ui
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookRoundedIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-head">
      <Link to="/">
        <h1>Sewix</h1>
      </Link>
      <div className="footer">
        <div className="footer-main">
          <a href="tel: +91 9354237363">
            <CallIcon fontSize="small" /> +91 9354237363
          </a>
          <a href="mailto: sewixofficial@gmail.com">
            <EmailIcon /> Sewixofficial@gmail.com
          </a>
          <div className="footer-icons">
            <a href="/">
              <WhatsAppIcon className="mui-icons" />
            </a>
            <a href="/">
              <FacebookRoundedIcon className="mui-icons" />
            </a>
            <a href="/">
              <InstagramIcon className="mui-icons" />
            </a>
            <a href="/">
              <TwitterIcon className="mui-icons" />
            </a>
          </div>
        </div>

        <div className="footer-navs">
          <Link to="/">Home</Link>
          <Link to="products">Products</Link>
          <Link to="contact">Contact</Link>
          <Link to="about">About</Link>
        </div>
      </div>
      <h3>Copyright @ Sewix 2022</h3>
    </footer>
  );
};

export default Footer;
