import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import tension from "../../../../images/spare parts/juki-781/tension2.jpg";

const TensionTwo781 = () => {
  return (
    <div>
      <ProductPage
        image={tension}
        alt="juki buttonhole tension"
        title="Juki Buttonhole Tension Set (Upper)"
        price={50}
        del_price={80}
        discount={39}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Tension Set"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit with Juki LBH-781, LBH-780, LBH-772"
        Highlight_4="Imported Product"
        key_features="Tension Set For Juki ButtonHole Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Tension"
        compatibility="Fit with Juki LBH-781, LBH-780, LBH-772"
        material="High Quality Steel"
      />
    </div>
  )
}

export default TensionTwo781