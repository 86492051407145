import React from "react";
import SEO from "../../components/SEO";
import Product from "../../components/Product";
import ViewAll from "../../components/ViewAll";
import { Link } from "react-router-dom";

// Images
// Images
// import esda_1 from "../../images/esda/esda-new-1.jpg";
// import vicco_1 from "../../images/vicco-priya/vicco-priya-1-new.jpg";
// import hmc_1 from "../../images/hmc/hmc-1-new.jpg";
// import breostar from "../../images/breostar-motor/breostar2.png";
import s1_spare from "../../images/shuttle.jpeg";
import bush from "../../images/spare parts/single-needle/bush.jpg";
import juki_boot from "../../images/spare parts/single-needle/juki-boot.jpg";
import juki_plate from "../../images/spare parts/single-needle/juki-plate.jpg";
import needle_bar from "../../images/spare parts/single-needle/needle-bar.jpg";

import juki_tension from "../../images/spare parts/single-needle/tension-juki.jpg";
import gauge_set from "../../images/m700-spares/m700-gauge-set.jpg";
import looper1 from "../../images/m700-spares/204072-looper.jpg";
import looper2 from "../../images/m700-spares/204702-looper.jpg";
// import m700_feed_dog from "../../images/m700-spares/m700-feed-dog.jpg";
import m700_needle_clamp from "../../images/m700-spares/m700-needle-clamp.jpg";
import m700_needle_plate from "../../images/m700-spares/m700-needle-plate.jpg";
import m700_pressure_foot from "../../images/m700-spares/m700-pressure-foot.jpg";
import shuttle781 from "../../images/spare parts/juki-781/shuttle.jpg";
import bobbin_case_781 from "../../images/spare parts/juki-781/bobbin-case.jpg";
import bobbin781 from "../../images/spare parts/juki-781/bobbin.jpg";
import pressure_foot_781 from "../../images/spare parts/juki-781/pressure-foot.jpg";
import scissor781 from "../../images/spare parts/juki-781/scissor.jpg";
import tension1_781 from "../../images/spare parts/juki-781/tension1.jpg";

const SewingMachineParts = () => {
  return (
    <>
    {/* SEO props are title, description, name, type, url, main_image, keywords */}
<SEO
title="Sewing Machine Parts - Versatile and High-Quality - Comprehensive Selection"
description="Our collection of Sewing Machine Parts offers a versatile and high-quality solution for any sewing project. We have a comprehensive selection of components that suit any machine make and model. These parts are designed to be easy to install, with clear instructions and all necessary hardware included. Whether you're looking to replace worn out components or upgrade your machine, our parts are the perfect solution. Order now and keep your machine running smoothly."
name="Sewix"
type="website"
url="https://www.sewix.in/sewing-machine-parts"
main_image={shuttle781}
keywords="Sewing machine needle, Sewing machine bobbin, Sewing machine presser foot, Sewing machine thread, Sewing machine bobbin case, Sewing machine belt, Sewing machine foot pedal, Sewing machine power cord, Sewing machine feed dog, Sewing machine bobbin winder, Sewing machine tensioner, Sewing machine hook, Sewing machine light bulb, Sewing machine oil, Sewing machine needle plate, Sewing machine throat plate, Sewing machine zipper foot, Sewing machine blind hem foot, Sewing machine buttonhole foot, Sewing machine cording foot, Sewing machine gathering foot, Sewing machine overcasting foot, Sewing machine quilting foot, Sewing machine straight stitch foot, Sewing machine walking foot." />
      <ViewAll
        name="Parts for Lockstitch Single Needle"
        link="/lockstitch-sewing-machine-parts"
      />
      <div className="parts-product">
        <Link
          to="/yoke-hook-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={s1_spare}
            title="Hook Set Shuttle"
            price={199}
            del_price={300}
            off={38}
            alt="sewing machine hook set"
            img_title="Hook Set for Lockstitch Machine"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_boot}
            title="Pressure Foot for Single Needle"
            price={14}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_plate}
            title="Needle Plate for Single Needle"
            price={17}
            del_price={30}
            off={50}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link to="/tension" style={{ textDecoration: "none", color: "black" }}>
          <Product
            image={juki_tension}
            title="Tension for Single Needle"
            price={20}
            del_price={45}
            off={43}
            alt="sewing machine tension"
            img_title="Tension for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar-bush"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bush}
            title="Needle Bar Bush (High Quality)"
            price={50}
            del_price={85}
            off={45}
            alt="Needle bar bush"
            img_title="Needle Bar Bush for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_bar}
            title="Needle Bar for Single Needle"
            price={20}
            del_price={35}
            off={49}
            alt="Needle bar"
            img_title="Needle Bar for Single Needle"
          />
        </Link>
      </div>
      <ViewAll name="M-700 Overlock Parts" link="/m700-overlock-parts" />
      <div className="parts-product">
        <Link
          to="/m700-gauge-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={gauge_set}
            title="M-700 Gauge Set"
            price={350}
            del_price={700}
            off={39}
            alt="m700 guage set"
            img_title="M-700 Gauge Set"
          />
        </Link>
        <Link
          to="/m700-looper-204072"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper1}
            title="M-700 Looper 204072"
            price={50}
            del_price={70}
            off={35}
            alt="204072 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-looper-204702"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper2}
            title="M-700 Looper 204702"
            price={45}
            del_price={70}
            off={35}
            alt="204702 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_plate}
            title="M-700 Needle Plate"
            price={70}
            del_price={110}
            off={28}
            alt="m700 needle plate"
            img_title="M-700 Needle Plate"
          />
        </Link>
        <Link
          to="/m700-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_pressure_foot}
            title="M-700 Pressure Foot"
            price={60}
            del_price={85}
            off={21}
            alt="m700 pressure foot"
            img_title="M-700 Pressure Foot"
          />
        </Link>
        <Link
          to="/m700-needle-clamp"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_clamp}
            title="M-700 Needle Clamp"
            price={70}
            del_price={110}
            off={39}
            alt="m700 needle clamp"
            img_title="M-700 Needle Clamp"
          />
        </Link>
      </div>
      {/* Juki 781 Spares  */}
      <ViewAll name="Juki Buttonhole Parts" link="/juki-buttonhole-parts" />
      <div className="parts-product">
        <Link
          to="/juki-butttonhole-shuttle"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={shuttle781}
            title="Rnd Original Shuttle / Hook Set"
            price={1100}
            del_price={700}
            off={39}
            alt="juki buttonhole shuttle"
            img_title="Rnd Hook Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin-case"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin_case_781}
            title="Haya Bobbin Case"
            price={350}
            del_price={400}
            off={23}
            alt="haya bobbin case"
            img_title="Haya Bobbin Case"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin781}
            title="Bobbin"
            price={40}
            del_price={70}
            off={65}
            alt="juki bobbin"
            img_title="Bobbin"
          />
        </Link>
        <Link
          to="/juki-butttonhole-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_781}
            title="Pressure Foot"
            price={270}
            del_price={350}
            off={28}
            alt="pressure foot"
            img_title="Pressure Foot"
          />
        </Link>
        <Link
          to="/juki-butttonhole-scissor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={scissor781}
            title="Scissor Set"
            price={250}
            del_price={350}
            off={28}
            alt="juki buttonhole scissor"
            img_title="Scissor Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-lower-tension"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={tension1_781}
            title="Tension Set (Lower)"
            price={55}
            del_price={80}
            off={39}
            alt="tension set"
            img_title="Tension Set"
          />
        </Link>
      </div>
    </>
  );
};

export default SewingMachineParts;
