import React from "react";
// import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import YamunaSewingMachine from "./components/ProductPage";
import "./styles/App.css";
import Header from "./components/Header";
import NavbarMobile from "./components/NavbarMobile";
import Footer from "./components/Footer";
import WhatsappIcon from "./components/WhatsappIcon";
import ProductsPage from "./pages/ProductsPage";
import Contactpage from "./pages/ContactPage";
import DomesticSewingMachine from "./pages/categories/DomesticSewingMachine";

// REACT-GA
// import ReactGA from "react-ga";
//MOTOR...........................................................
import ViccoPriyaServoMotor from "./pages/Product_Pages/ServoMotors/ViccoPriyaServoMotor";
import EsdaPowerSavingMotor from "./pages/Product_Pages/ServoMotors/EsdaPowerSavingMotor";
import HmcServoMotor from "./pages/Product_Pages/ServoMotors/HmcServoMotor";
import BreostarServoMotor from "./pages/Product_Pages/ServoMotors/BreostarServoMotor";
import HmcBudget from "./pages/Product_Pages/ServoMotors/HmcBudget";
import ServoMotor from "./pages/categories/ServoMotor";
import MesserServo from "./pages/Product_Pages/ServoMotors/MesserServo";

//PCB BOARD.......................................................
import JackF4Pcb from "./pages/Product_Pages/PCB/JackF4Pcb";
import Jack804DPcb from "./pages/Product_Pages/PCB/Jack804DPcb";
import JackA2Pcb from "./pages/Product_Pages/PCB/JackA2Pcb";
import JackA2sPcb from "./pages/Product_Pages/PCB/JackA2sPcb";
import EsdaPcb from "./pages/Product_Pages/PCB/EsdaPcb";
import PcbBoard from "./pages/categories/PcbBoard"

import SewingMachineAccessories from "./pages/Product_Pages/SewingMachineAccessories";
import Page404 from "./pages/Page404";
import SewingMachineParts from "./pages/categories/SewingMachineParts";
import YokeShuttle from "./pages/Product_Pages/Spare_Parts/SingleNeedle/YokeShuttle";
import PressureFoot from "./pages/Product_Pages/Spare_Parts/SingleNeedle/PressureFoot";
import NeedlePlate from "./pages/Product_Pages/Spare_Parts/SingleNeedle/NeedlePlate";
import NeedleBarBush from "./pages/Product_Pages/Spare_Parts/SingleNeedle/NeedleBarBush";
import Tension from "./pages/Product_Pages/Spare_Parts/SingleNeedle/Tension";
import NeedleBar from "./pages/Product_Pages/Spare_Parts/SingleNeedle/NeedleBar";
import HookSetsCat from "./pages/SparePartsCategory/HookSetsCat";
import PressureFootsCat from "./pages/SparePartsCategory/PressureFootsCat";
import TensionsCat from "./pages/SparePartsCategory/TensionsCat";
import NeedlePlatesCat from "./pages/SparePartsCategory/NeedlePlatesCat";
import BobbinCasesCat from "./pages/SparePartsCategory/BobbinCasesCat";
import FeedDogsCat from "./pages/SparePartsCategory/FeedDogsCat";
import NavbarDesk from "./components/NavbarDesk";
import UshaBandhan from "./pages/Product_Pages/UshaBandhan";
import GaugeSet from "./pages/Product_Pages/Spare_Parts/m700/GaugeSet";
import Looper1 from "./pages/Product_Pages/Spare_Parts/m700/Looper1";
import Looper2 from "./pages/Product_Pages/Spare_Parts/m700/Looper2";
import M700NeedlePlate from "./pages/Product_Pages/Spare_Parts/m700/NeedlePlate";
import M700PressureFoot from "./pages/Product_Pages/Spare_Parts/m700/M700PressureFoot";
import M700NeedleClamp from "./pages/Product_Pages/Spare_Parts/m700/M700NeedleClamp";
import Categories from "./pages/categories/Categories";
import SingleNeedle from "./pages/SparePartsCategory/SingleNeedle";
import M700Overlock from "./pages/SparePartsCategory/M700Overlock";
import GaugeSetSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/GaugeSet";
import BobbinCaseSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/BobbinCaseSN";
import PumpSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/PumpSN";
import ThreadGuidePinSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/ThreadGuidePinSN";
import PressureFootLifterSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/PressureFootLifterSN";
import HeadRestSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/HeadRestSN";
import HingesSetSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/HingesSetSN";
import KundiCoverSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/KundiCoverSN";
import OilSealSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/OilSealSN";
import OilWindowSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/OilWindowSN";
import PressureCapSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/PressureCapSN";
import PulleySN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/PulleySN";
import TankKilliSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/TankKilliSN";
import ThreadGuide2HoleSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/ThreadGuide2HoleSN";
import ThreadGuide3HoleSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/ThreadGuide3HoleSN";
import BaramSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/BaramSN";
import BigLifterSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/BigLifterSN";
import ChaalSpring1SN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/ChaalSpring1SN";
import ChaalSpring2SN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/ChaalSpring2SN";
import FolderAdjusterSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FolderAdjusterSN";
import DialSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/DialSN";
import KundiSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/KundiSN";
import ThreadGuideLeftSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/ThreadGuideLeftSN";
import ThreadGuideRightSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/ThreadGuideRightSN";
import RubberJointTSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/RubberJointTSN";
import SlidePlateSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/SlidePlateSN";
import SpringRodSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/SpringRodSN";
import StopperSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/StopperSN";
import UbtTensionSN from "./pages/Product_Pages/Spare_Parts/SingleNeedle/UbtTensionSN";
import ButtonHoleCat from "./pages/SparePartsCategory/ButtonHoleCat";
import Shuttle781 from "./pages/Product_Pages/Spare_Parts/Juki_781/Shuttle781";
import BobbinCase781 from "./pages/Product_Pages/Spare_Parts/Juki_781/BobbinCase781";
import Bobbin781 from "./pages/Product_Pages/Spare_Parts/Juki_781/Bobbin781";
import PressureFoot781 from "./pages/Product_Pages/Spare_Parts/Juki_781/PressureFoot781";
import Scissor781 from "./pages/Product_Pages/Spare_Parts/Juki_781/Scissor781";
import TensionOne781 from "./pages/Product_Pages/Spare_Parts/Juki_781/TensionOne781";
import TensionTwo781 from "./pages/Product_Pages/Spare_Parts/Juki_781/TensionTwo781";
import FeedDogBType from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDogBType";
import FeedDogEType from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDogEType";
import FeedDogHType from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDogHType";
// import FeedDogBType_UBT from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDogBTypeUBT";
// import FeedDog12482_309 from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDog12482Dash309";
import FeedDog149057R from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDog149057R";
import FeedDogXDN18T from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDogXDN18T";
import FeedDogBTypeUBT from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDogBTypeUBT";
import FeedDog12482Dash309 from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDog12482Dash309";
import FeedDog12481R from "./pages/Product_Pages/Spare_Parts/SingleNeedle/FeedDog/FeedDog12481R";
import NeedlesCat from "./pages/SparePartsCategory/NeedlesCat";
import FoldersCat from "./pages/SparePartsCategory/FoldersCat";
import LoopersCat from "./pages/SparePartsCategory/LoopersCat";
import AkariServoMotor from "./pages/Product_Pages/ServoMotors/AkariServoMotor";
import GrozNeedleDB1 from "./pages/Product_Pages/Spare_Parts/Needle/GrozNeedleDB1";
import FlyingTigerNeedleDB1 from "./pages/Product_Pages/Spare_Parts/Needle/FlyingTigerNeedleDB1";
import WhiteCraneNeedleDB1 from "./pages/Product_Pages/Spare_Parts/Needle/WhiteCraneNeedleDB1";
import OrganNeedleDB1 from "./pages/Product_Pages/Spare_Parts/Needle/OrganNeedleDB1";
import SchmetzDC1 from "./pages/Product_Pages/Spare_Parts/Needle/SchmetzDC1";
import PonyNeedle from "./pages/Product_Pages/Spare_Parts/Needle/PonyNeedle";
import JinServo from "./pages/Product_Pages/ServoMotors/JinServo";
import JackServo from "./pages/Product_Pages/ServoMotors/JackServo";
import EsdaDD from "./pages/Product_Pages/ServoMotors/EsdaDD";
import HmcDD from "./pages/Product_Pages/ServoMotors/HmcDD";
import OverlockDD from "./pages/Product_Pages/ServoMotors/OverlockServo";
import Servo1 from "./pages/Product_Pages/ServoMotors/Servo1";
import Servo2 from "./pages/Product_Pages/ServoMotors/Servo2";
import SteamPress from "./pages/categories/SteamPress";
import EsdaSteam from "./pages/Product_Pages/SteamPress/EsdaSteam";
import SiltiPro from "./pages/Product_Pages/SteamPress/SiltiPro";
import WeijieSteam from "./pages/Product_Pages/SteamPress/WeijieSteam";
import CuttingMachine from "./pages/categories/CuttingMachine";
import Breostar110 from "./pages/Product_Pages/CuttingMachine/Breostar110";
import Breostar8 from "./pages/Product_Pages/CuttingMachine/Breostar8";
import Lippu8 from "./pages/Product_Pages/CuttingMachine/Lippu8";
import Taking65 from "./pages/Product_Pages/CuttingMachine/Taking65";
import Breostar125 from "./pages/Product_Pages/CuttingMachine/Breostar125";
import Breostar125H from "./pages/Product_Pages/CuttingMachine/Breostar125H";
import Silti96 from "./pages/Product_Pages/SteamPress/Silti96";
import Silti300LB from "./pages/Product_Pages/SteamPress/Silti300LB";
import SilverStar1 from "./pages/Product_Pages/SteamPress/SilverStar1";
import SilverStar2 from "./pages/Product_Pages/SteamPress/SilverStar2";
import YokeSteam from "./pages/Product_Pages/SteamPress/YokeSteam";
import IndustrialIron from "./pages/Product_Pages/SteamPress/IndustrialIron";
import Cupid110 from "./pages/Product_Pages/CuttingMachine/Cupid110";
import Cupid125 from "./pages/Product_Pages/CuttingMachine/Cupid125";
import Dtech110 from "./pages/Product_Pages/CuttingMachine/Dtech110";
import Dtech125 from "./pages/Product_Pages/CuttingMachine/Dtech125";
import Taking110 from "./pages/Product_Pages/CuttingMachine/Taking110";
import Taking125 from "./pages/Product_Pages/CuttingMachine/Taking125";
import Jack9100BP from "./pages/Product_Pages/PCB/Jack9100BP";
import Jack9100BA from "./pages/Product_Pages/PCB/Jack9100BA";

// ReactGA.initialize('G-3KHH7D1N2F');


function App() {
  // useEffect(() => {
  //   ReactGA.initialize('G-3KHH7D1N2F');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <>
      {/* FOR EVERY PAGE  */}
      <div className="header-mobile">
        <Header />
      </div>
      <div className="navbar-desk">
        <NavbarDesk />
      </div>
      <hr />
      <div className="whatsapp-main">
        <WhatsappIcon />
      </div>
      <div className="navbar-mobile">
        <NavbarMobile />
      </div>

      {/* ROUTES  */}
      <Routes>
        {/* Main  */}
        <Route path="*" element={<Page404 />} />
        <Route path="/" element={<Home />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="contact" element={<Contactpage />} />
        {/* Categories  */}
        <Route path="Categories" element={<Categories />} />
        <Route path="sewing-machine-motor" element={<ServoMotor />} />
        <Route
          path="sewing-machine-accessories"
          element={<SewingMachineAccessories />}
        />
        <Route
          path="domestic-sewing-machine"
          element={<DomesticSewingMachine />}
        />
        <Route path="sewing-machine-parts" element={<SewingMachineParts />} />
        <Route path="hook-sets" element={<HookSetsCat />} />
        <Route path="pressure-foots" element={<PressureFootsCat />} />
        <Route path="tensions" element={<TensionsCat />} />
        <Route path="needle-plates" element={<NeedlePlatesCat />} />
        <Route path="feed-dogs" element={<FeedDogsCat />} />
        <Route path="bobbin-cases" element={<BobbinCasesCat />} />
        <Route path="needles" element={<NeedlesCat />} />
        <Route path="folders" element={<FoldersCat />} />
        <Route path="loopers" element={<LoopersCat />} />

        {/* Spare Parts Category  */}
        <Route
          path="lockstitch-sewing-machine-parts"
          element={<SingleNeedle />}
        />
        <Route path="m700-overlock-parts" element={<M700Overlock />} />
        <Route path="juki-buttonhole-parts" element={<ButtonHoleCat />} />

        {/* PRODUCTS  */}
        {/* MOTOR  */}
        <Route
          path="vicco-priya-servo-motor"
          element={<ViccoPriyaServoMotor />}
        />
        <Route
          path="esda-power-saving-motor"
          element={<EsdaPowerSavingMotor />}
        />
        <Route path="hmc-servo-motor" element={<HmcServoMotor />} />
        <Route path="breostar-servo-motor" element={<BreostarServoMotor />} />
        <Route path="akari-servo-motor" element={<AkariServoMotor />} />
        <Route path="messer-servo-motor" element={<MesserServo />} />
        <Route path="jin-servo-motor" element={<JinServo />} />
        <Route path="jack-servo-motor" element={<JackServo />} />
        <Route path="esda-direct-drive-motor" element={<EsdaDD />} />
        <Route path="hmc-direct-drive-motor" element={<HmcDD />} />
        <Route path="overlock-direct-drive-motor" element={<OverlockDD />} />
        <Route path="sewing-machine-motor-1" element={<Servo1 />} />
        <Route path="sewing-machine-motor-2" element={<Servo2 />} />
        <Route path="hmc-servo-motor-new" element={<HmcBudget />} />
        <Route
          path="/yamuna-sewing-machine"
          element={<YamunaSewingMachine />}
        />
        <Route path="usha-bandhan-straight-stitch" element={<UshaBandhan />} />

        {/* PCB BOARD */}
        <Route path="pcb-board" element={<PcbBoard />} />
        <Route path="jack-f4-pcb" element={<JackF4Pcb />} />
        <Route path="jack-9100bp-pcb" element={<Jack9100BP />} />
        <Route path="jack-9100ba-pcb" element={<Jack9100BA />} />
        <Route path="jack-a2-pcb" element={<JackA2Pcb />} />
        <Route path="jack-a2s-pcb" element={<JackA2sPcb />} />
        <Route path="jack-804d-pcb" element={<Jack804DPcb />} />
        <Route path="esda-servo-motor-pcb" element={<EsdaPcb />} />

        {/* STEAM PRESS */}
        <Route path="steam-press-iron" element={<SteamPress />} />
        <Route path="esda-steam-press-iron" element={<EsdaSteam />} />
        <Route path="silti-pro-steam-press-iron" element={<SiltiPro />} />
        <Route path="weijie-full-hole-steam-press-iron" element={<WeijieSteam />} />
        <Route path="silti-st96-steam-press-iron" element={<Silti96 />} />
        <Route path="silti-es300lb-steam-press-iron" element={<Silti300LB />} />
        <Route path="silver-star-1-steam-press-iron" element={<SilverStar1 />} />
        <Route path="silver-star-2-steam-press-iron" element={<SilverStar2 />} />
        <Route path="yoke-steam-press-iron" element={<YokeSteam />} />
        <Route path="industrial-iron-steam-press-iron" element={<IndustrialIron />} />


        {/* CUTTING MACHINE */}
        <Route path="cloth-cutting-machine" element={<CuttingMachine />} />
        <Route path="breostar-110mm-cloth-cutting-machine" element={<Breostar110 />} />
        <Route path="breostar-125mm-cloth-cutting-machine" element={<Breostar125 />} />
        <Route path="taking-65mm-cloth-cutting-machine" element={<Taking65 />} />
        <Route path="breostar-125mm-heavy-cloth-cutting-machine" element={<Breostar125H />} />
        <Route path="lippu-8inch-cloth-cutting-machine" element={<Lippu8 />} />
        <Route path="breostar-8inch-cloth-cutting-machine" element={<Breostar8 />} />
        <Route path="cupid-110mm-cloth-cutting-machine" element={<Cupid110 />} />
        <Route path="cupid-125mm-cloth-cutting-machine" element={<Cupid125 />} />
        <Route path="dtech-110mm-cloth-cutting-machine" element={<Dtech110 />} />
        <Route path="dtech-125mm-cloth-cutting-machine" element={<Dtech125 />} />
        <Route path="taking-110mm-cloth-cutting-machine" element={<Taking110 />} />
        <Route path="taking-125mm-cloth-cutting-machine" element={<Taking125 />} />

        {/* SPARE PARTS  */}
        {/* NEEDLE  */}
        <Route path="groz-beckert-needle-db1" element={<GrozNeedleDB1 />} />
        <Route
          path="flying-tiger-needle-db1"
          element={<FlyingTigerNeedleDB1 />}
        />
        <Route
          path="white-crane-needle-db1"
          element={<WhiteCraneNeedleDB1 />}
        />
        <Route path="organ-needle-db1" element={<OrganNeedleDB1 />} />
        <Route path="schmetz-needle-dc1" element={<SchmetzDC1 />} />
        <Route path="pony-needle" element={<PonyNeedle />} />

        {/* Single Needle  */}
        <Route path="yoke-hook-set" element={<YokeShuttle />} />
        <Route path="pressure-foot" element={<PressureFoot />} />
        <Route path="needle-plate" element={<NeedlePlate />} />
        <Route path="needle-bar-bush" element={<NeedleBarBush />} />
        <Route path="tension" element={<Tension />} />
        <Route path="needle-bar" element={<NeedleBar />} />
        <Route
          path="guage-set-for-lockstich-machine"
          element={<GaugeSetSN />}
        />
        <Route
          path="bobbin-case-for-lockstitch-machine"
          element={<BobbinCaseSN />}
        />
        <Route path="oil-pump-for-lockstitch-machine" element={<PumpSN />} />
        <Route
          path="thread-guide-pin-for-lockstitch-machine"
          element={<ThreadGuidePinSN />}
        />
        <Route
          path="pressure-foot-lifter-for-lockstitch-machine"
          element={<PressureFootLifterSN />}
        />
        <Route
          path="head-rest-for-lockstitch-machine"
          element={<HeadRestSN />}
        />
        <Route
          path="hinges-set-for-lockstitch-machine"
          element={<HingesSetSN />}
        />
        <Route
          path="kundi-cover-for-lockstitch-machine"
          element={<KundiCoverSN />}
        />
        <Route path="oil-seal-for-lockstitch-machine" element={<OilSealSN />} />
        <Route
          path="oil-window-for-lockstitch-machine"
          element={<OilWindowSN />}
        />
        <Route
          path="pressure-cap-for-lockstitch-machine"
          element={<PressureCapSN />}
        />
        <Route path="pulley-for-lockstitch-machine" element={<PulleySN />} />
        <Route
          path="Tank-Killi-for-lockstitch-machine"
          element={<TankKilliSN />}
        />
        <Route
          path="2-hole-thread-guide-for-lockstitch-machine"
          element={<ThreadGuide2HoleSN />}
        />
        <Route
          path="3-hole-thread-guide-for-lockstitch-machine"
          element={<ThreadGuide3HoleSN />}
        />
        <Route path="dial-part-for-lockstitch-machine" element={<BaramSN />} />
        <Route path="lifter-for-lockstitch-machine" element={<BigLifterSN />} />
        <Route
          path="chaal-spring-1-for-lockstitch-machine"
          element={<ChaalSpring1SN />}
        />
        <Route
          path="chaal-spring-2-for-lockstitch-machine"
          element={<ChaalSpring2SN />}
        />
        <Route
          path="folder-adjuster-for-lockstitch-machine"
          element={<FolderAdjusterSN />}
        />
        <Route path="Dial-for-lockstitch-machine" element={<DialSN />} />
        <Route path="Kundi-set-for-lockstitch-machine" element={<KundiSN />} />
        <Route
          path="thread-guide-left-for-lockstitch-machine"
          element={<ThreadGuideLeftSN />}
        />
        <Route
          path="thread-guide-right-for-lockstitch-machine"
          element={<ThreadGuideRightSN />}
        />
        <Route
          path="thread-guide-right-for-lockstitch-machine"
          element={<ThreadGuideRightSN />}
        />
        <Route
          path="rubber-joint-for-lockstitch-machine"
          element={<RubberJointTSN />}
        />
        <Route
          path="slide-plate-for-lockstitch-machine"
          element={<SlidePlateSN />}
        />
        <Route
          path="spring-rod-for-lockstitch-machine"
          element={<SpringRodSN />}
        />
        <Route path="stopper-for-lockstitch-machine" element={<StopperSN />} />
        <Route
          path="ubt-tension-for-lockstitch-machine"
          element={<UbtTensionSN />}
        />
        {/* Pressure Foot  */}
        <Route
          path="btype-feed-dog-for-lockstitch-machine"
          element={<FeedDogBType />}
        />
        <Route
          path="etype-feed-dog-for-lockstitch-machine"
          element={<FeedDogEType />}
        />
        <Route
          path="htype-feed-dog-for-lockstitch-machine"
          element={<FeedDogHType />}
        />
        <Route
          path="btype-ubt-feed-dog-for-lockstitch-machine"
          element={<FeedDogBTypeUBT />}
        />
        <Route
          path="12482-309-feed-dog-for-lockstitch-machine"
          element={<FeedDog12482Dash309 />}
        />
        <Route
          path="149057r-feed-dog-for-lockstitch-machine"
          element={<FeedDog149057R />}
        />
        <Route
          path="xdn18t-feed-dog-for-lockstitch-machine"
          element={<FeedDogXDN18T />}
        />
        <Route
          path="12481r-feed-dog-for-lockstitch-machine"
          element={<FeedDog12481R />}
        />

        {/* M-700  */}
        <Route path="m700-gauge-set" element={<GaugeSet />} />
        <Route path="m700-looper-204072" element={<Looper1 />} />
        <Route path="m700-looper-204702" element={<Looper2 />} />
        <Route path="m700-needle-plate" element={<M700NeedlePlate />} />
        <Route path="m700-pressure-foot" element={<M700PressureFoot />} />
        <Route path="m700-needle-clamp" element={<M700NeedleClamp />} />

        {/* Juki Buttonhole  */}
        <Route path="juki-butttonhole-shuttle" element={<Shuttle781 />} />
        <Route
          path="juki-butttonhole-bobbin-case"
          element={<BobbinCase781 />}
        />
        <Route path="juki-butttonhole-bobbin" element={<Bobbin781 />} />
        <Route
          path="juki-butttonhole-pressure-foot"
          element={<PressureFoot781 />}
        />
        <Route path="juki-butttonhole-scissor" element={<Scissor781 />} />
        <Route
          path="juki-butttonhole-lower-tension"
          element={<TensionOne781 />}
        />
        <Route
          path="juki-butttonhole-upper-tension"
          element={<TensionTwo781 />}
        />
      </Routes>

      {/* FOOTER  */}
      <Footer />
    </>
  );
}

export default App;
