import React from "react";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import jack_servo from "../../../images/servo-motor/jack-servo.jpg";

const JackServo = () => {
  return (
    <div>
      <ProductPage
        image={jack_servo}
        alt="jack servo motor"
        img_title="Jack Servo Motor"
        title="Jack Power Saving Servo Motor"
        price={4299}
        del_price={6000}
        discount={32}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="1 Year Warranty"
        box_content="Motor, Board, Fittings, Connecting Rod, User Manual"
        Highlight_1="Power Saves upto 75%"
        Highlight_2="Aluminium Body"
        Highlight_3="Speed Controller"
        Highlight_4="LED Display"
        key_features="Jack - A famous brand of servo sewing machine motor. A good option to change your traditional sewing machine motor, can replace your old sewing machne motor into esda sewing machine motor. Breostar Safe your power & reduce your electricity due to its steping rotation technology because there is no electro magnetic based technology. There is multiple speed 2800 rpm to 5000 rpm. can change your rotationn side according to your need can reduse your electrycity bill amount at least 60 - 75%."
      />
      <Specs brand_name="Jack"
      model_name="Servo"
      color="Cream & Silver"
      type="Power Saving"
      material="Aluminium"
      speed="5000 rpm"
      shape="Round"
       />
    </div>
  );
};

export default JackServo;
