import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import looper1 from "../../../../images/m700-spares/204072-looper.jpg";

const Looper1 = () => {
  return (
    <div>
      <ProductPage
        image={looper1}
        alt="m700 looper 204702"
        img_title="Looper 204702"
        title="Looper for M-700 Overlock 204072"
        price={45}
        del_price={70}
        discount={35}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Looper"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Big Looper for M700 Model 4 Thread Overlock Sewing Machine."
      />
      <SpecsSpares
        brand_name="Generic"
        type="Looper"
        compatibility="Pegasus M700 Overlock Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  );
};

export default Looper1;
