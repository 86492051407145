import React from "react";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import vicco_priya from "../../../images/vicco-priya/vicco-priya-1-new.jpg";

const ViccoPriyaServoMotor = () => {
  return (
    <div>
      <ProductPage
        image={vicco_priya}
        alt="vicco priya servo motor"
        img_title="Vicco Priya Servo Motor"
        title="Vicco Priya Power Saving Servo Motor"
        price={4299}
        del_price={4999}
        discount={12}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="1 Year Warranty"
        box_content="Motor, Board, Fittings, Connecting Rod, User Manual"
        Highlight_1="Power Saves upto 75%"
        Highlight_2="Aluminium Body"
        Highlight_3="Speed Controller"
        Highlight_4="LED Display"
        key_features="Vicco Priya - A famous brand of servo sewing machine motor. A good option to change your traditional sewing machine motor, can replace your old sewing machne motor into esda sewing machine motor. Vicco Priya Safe your power & reduce your electricity due to its steping rotation technology because there is no electro magnetic based technology. There is multiple speed 2800 rpm to 5000 rpm. can change your rotationn side according to your need can reduse your electrycity bill amount at least 60 - 75%. You will also get 1 Year of off-side warranty."
      />
      <Specs
        brand_name="Vicco"
        model_name="Priya"
        color="Grey"
        type="Power Saving"
        material="Aluminium"
        speed="5000"
        shape="Round"
      />
    </div>
  );
};

export default ViccoPriyaServoMotor;
