import React from "react";

const SpecsSpares = ({
  brand_name,
  type,
  material,
  compatibility,
}) => {
  return (
    <div className="specs">
      <h4>Specification:</h4>
      <table className="specs-table">
        <tr className="specs-flex">
          <td className="product-td">Brand:</td>
          <td>{brand_name}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Type:</td>
          <td>{type}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Compatibility:</td>
          <td>{compatibility}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Material:</td>
          <td>{material}</td>
        </tr>
      </table>
    </div>
  );
};

export default SpecsSpares;
