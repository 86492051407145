import React from "react";
import "../../styles/categories/SparePartsCategory.css";
import { Link } from "react-router-dom";
import Product from "../../components/Product";

// images
import rnd_hook from "../../images/single-needle-parts/rnd-hook.jpg";
import s1_spare from "../../images/shuttle.jpeg";
import bush from "../../images/spare parts/single-needle/bush.jpg";
import juki_boot from "../../images/spare parts/single-needle/juki-boot.jpg";
import juki_plate from "../../images/spare parts/single-needle/juki-plate.jpg";
import needle_bar from "../../images/spare parts/single-needle/needle-bar.jpg";
import needle_bar_1 from "../../images/single-needle-parts/needle-bar.jpg";

import juki_tension from "../../images/spare parts/single-needle/tension-juki.jpg";
import pump from "../../images/spare parts/single-needle/pump-new.jpg";
import head_killi from "../../images/spare parts/single-needle/head-killi.jpg";
import chota_lifter from "../../images/spare parts/single-needle/chota-lifter.jpg";
import bobbin_case from "../../images/spare parts/single-needle/bobbin-case.jpg";
import head_rest from "../../images/spare parts/single-needle/head-rest.jpg";
import hinges from "../../images/spare parts/single-needle/hinjes.jpg";
import kundi_cover from "../../images/spare parts/single-needle/kundi-cover.jpg";
import oil_seal from "../../images/spare parts/single-needle/oil-seal.jpg";
import oil_window from "../../images/spare parts/single-needle/oil-window.jpg";
import pressure_cap from "../../images/spare parts/single-needle/pressure-cap.jpg";
import pulley from "../../images/spare parts/single-needle/pulley.jpg";
import tank_killi from "../../images/spare parts/single-needle/tank-killi.jpg";
// import gauge_set from "../../images/spare parts/single-needle/gauge-set.jpg";
import hole_guide_2 from "../../images/spare parts/single-needle/2hole-guide.jpg";
import hole_guide_3 from "../../images/spare parts/single-needle/3hole-guide.jpg";
import baram from "../../images/spare parts/single-needle/baram.jpg";
import big_lifter from "../../images/spare parts/single-needle/big-lifter.jpg";
import chaal_spring_small from "../../images/spare parts/single-needle/chaal-spring-small.jpg";
import chaal_spring from "../../images/spare parts/single-needle/chal-spring.jpg";
import chawanni from "../../images/spare parts/single-needle/chawanni.jpg";
import dial from "../../images/spare parts/single-needle/dial.jpg";
import kundi from "../../images/spare parts/single-needle/kundi.jpg";
import right_guide from "../../images/spare parts/single-needle/right-guide.jpg";
import left_guide from "../../images/spare parts/single-needle/left-guide.jpg";

import T from "../../images/spare parts/single-needle/rubber-joint.jpg";
import slide from "../../images/spare parts/single-needle/slide-plate.jpg";
import spring_rod from "../../images/spare parts/single-needle/spring-rod.jpg";
import stopper from "../../images/spare parts/single-needle/stopper.jpg";
import ubt_tension from "../../images/spare parts/single-needle/ubt-tension.jpg";
import organ from "../../images/spare parts/single-needle/organ-db1.jpg";
import groz from "../../images/spare parts/single-needle/groz-db1.jpg";
import flying_tiger from "../../images/spare parts/single-needle/flying-tiger-db1.jpg";
import white_crane from "../../images/spare parts/single-needle/white-crane-db1.jpg";
import pressure_foot_211_14 from "../../images/spare parts/single-needle/pressure-foot-211-14.jpg";
import pressure_foot_12463 from "../../images/spare parts/single-needle/pressure-foot-12463.jpg";
import pressure_foot_cl_1_16n from "../../images/spare parts/single-needle/pressure-foot-cl-1-16n.jpg";
import pressure_foot_cr_3_16e from "../../images/spare parts/single-needle/pressure-foot-cr-3-16e.jpg";
import pressure_foot_mt18 from "../../images/spare parts/single-needle/pressure-foot-mt18.jpg";
import pressure_foot_p36ln from "../../images/spare parts/single-needle/pressure-foot-p36ln.jpg";
import pressure_foot_p36n from "../../images/spare parts/single-needle/pressure-foot-p36n.jpg";
import pressure_foot_p69lh_1_8 from "../../images/spare parts/single-needle/pressure-foot-p69lh-1-8.jpg";
import pressure_foot_p69rh from "../../images/spare parts/single-needle/pressure-foot-p69rh.jpg";
import pressure_foot_p127 from "../../images/spare parts/single-needle/pressure-foot-p127.jpg";
import pressure_foot_p363 from "../../images/spare parts/single-needle/pressure-foot-p363.jpg";
import pressure_foot_p952 from "../../images/spare parts/single-needle/pressure-foot-p952.jpg";
import pressure_foot_r141 from "../../images/spare parts/single-needle/pressure-foot-r141.jpg";
import pressure_foot_sp18 from "../../images/spare parts/single-needle/pressure-foot-sp18.jpg";
import pressure_foot_t350 from "../../images/spare parts/single-needle/pressure-foot-t350.jpg";
import pressure_foot_u192_u193 from "../../images/spare parts/single-needle/pressure-foot-u192-u193.jpg";
import needle_plate_229_65909 from "../../images/spare parts/single-needle/needle-plate-229-65909.jpg";
import needle_plate_b_type from "../../images/spare parts/single-needle/needle-plate-b-type.jpg";
import needle_plate_b1109_552_000 from "../../images/spare parts/single-needle/needle-plate-b1109-552-000.jpg";
import needle_plate_h26 from "../../images/spare parts/single-needle/needle-plate-h26.jpg";
import feed_dog_12481r from "../../images/spare parts/single-needle/feed-dog-12481r.jpg";
import feed_dog_12482_309 from "../../images/spare parts/single-needle/feed-dog-12482-309.jpg";
import feed_dog_149057r from "../../images/spare parts/single-needle/feed-dog-149057r.jpg";
// import feed_dog_b1613_012_100 from "../../images/spare parts/single-needle/feed-dog-b1613-012-100.jpg";
import feed_dog_btype from "../../images/spare parts/single-needle/feed-dog-btype.jpg";
import feed_dog_etype from "../../images/spare parts/single-needle/feed-dog-etype.jpg";
import feed_dog_htype from "../../images/spare parts/single-needle/feed-dog-htype.jpg";
import feed_dog_ubt_etype from "../../images/spare parts/single-needle/feed-dog-ubt-etype.jpg";
import feed_dog_xdn18t from "../../images/spare parts/single-needle/feed-dog-xdn18t.jpg";







const SingleNeedle = () => {
  return (
    <div>
      <h4 className="sub-title">Lockstitch Sewing Machine Parts</h4>
      <div className="spare-parts-categories">
      <Link
          to="/flying-tiger-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={flying_tiger}
            title="Flying Tiger Needle DBX1"
            price={15}
            del_price={25}
            off={35}
            alt="flying tiger needle"
            img_title="Flying Tiger Needle"
          />
        </Link>
        <Link
          to="/groz-beckert-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={groz}
            title="Groz-Beckert Needle DBX1"
            price={55}
            del_price={80}
            off={48}
            alt="groz beckart needle"
            img_title="Groz Beckart"
          />
        </Link>
        <Link
          to="/white-crane-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={white_crane}
            title="White Crane Needle DBX1"
            price={25}
            del_price={40}
            off={35}
            alt="white crane needle"
            img_title="White Crane Needle"
          />
        </Link>
        <Link
          to="/organ-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={organ}
            title="Organ Needle DBX1"
            price={75}
            del_price={90}
            off={20}
            alt="organ needle"
            img_title="Organ Needle"
          />
        </Link>
        <Link
          to="/yoke-hook-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={s1_spare}
            title="YangZhou Hook Set Shuttle"
            price={199}
            del_price={300}
            off={38}
            alt="sewing machine hook set"
            img_title="Hook Set for Lockstitch Machine"
          />
        </Link>
        <Link
          to="/yoke-hook-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={s1_spare}
            title="Yoke Hook Set Shuttle"
            price={219}
            del_price={300}
            off={38}
            alt="sewing machine hook set"
            img_title="Hook Set for Lockstitch Machine"
          />
        </Link>
        <Link
          to="/yoke-hook-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={rnd_hook}
            title="RND Hook Set Shuttle"
            price={399}
            del_price={600}
            off={38}
            alt="sewing machine hook set"
            img_title="Hook Set for Lockstitch Machine"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_plate}
            title="E-Type Needle Plate"
            price={19}
            del_price={40}
            off={50}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_plate_b_type}
            title="B-Type Needle Plate"
            price={19}
            del_price={40}
            off={50}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_plate_h26}
            title="H26 Needle Plate"
            price={35}
            del_price={70}
            off={50}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_plate_229_65909}
            title="229-65909 Needle Plate"
            price={35}
            del_price={70}
            off={50}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_plate_b1109_552_000}
            title="B1109-552-000 Needle Plate"
            price={35}
            del_price={70}
            off={50}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link
          to="/etype-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_etype}
            title="E-Type Feed Dog"
            price={19}
            del_price={40}
            off={50}
            alt="E-Type Feed Dog"
            img_title="E-Type Feed Dog"
          />
        </Link>
        <Link
          to="/btype-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_btype}
            title="B-Type Feed Dog"
            price={19}
            del_price={40}
            off={50}
            alt="B-Type Feed Dog"
            img_title="B-Type Feed Dog"
          />
        </Link>
        <Link
          to="/htype-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_htype}
            title="H-Type Feed Dog"
            price={35}
            del_price={50}
            off={50}
            alt="H-Type Feed Dog"
            img_title="H-Type Feed Dog"
          />
        </Link>
        <Link
          to="/12481r-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_12481r}
            title="12481R Feed Dog"
            price={25}
            del_price={50}
            off={50}
            alt="12481R Feed Dog"
            img_title="12481R Feed Dog"
          />
        </Link>
        <Link
          to="/12482-309-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_12482_309}
            title="12482-309 Feed Dog"
            price={25}
            del_price={50}
            off={50}
            alt="needle plate"
            img_title="12482-309 Feed Dog"
          />
        </Link>
        <Link
          to="/149057r-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_149057r}
            title="149057R Feed Dog"
            price={25}
            del_price={50}
            off={50}
            alt="149057R Feed Dog"
            img_title="149057R Feed Dog"
          />
        </Link>
        <Link
          to="/btype-ubt-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_ubt_etype}
            title="B-Type UBT Feed Dog"
            price={25}
            del_price={50}
            off={50}
            alt="B-Type UBT Feed Dog"
            img_title="B-Type UBT Feed Dog"
          />
        </Link>
        <Link
          to="/xdn18t-feed-dog-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={feed_dog_xdn18t}
            title="XDN18T Feed Dog"
            price={25}
            del_price={50}
            off={50}
            alt="XDN18T Feed Dog"
            img_title="XDN18T Feed Dog"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_boot}
            title="P351 Pressure Foot for Single Needle"
            price={15}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_211_14}
            title="211-14 Pressure Foot for Single Needle"
            price={50}
            del_price={100}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_12463}
            title="12463 Pressure Foot for Single Needle"
            price={50}
            del_price={100}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_cl_1_16n}
            title="CL 1/16N Pressure Foot for Single Needle"
            price={29}
            del_price={60}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_cr_3_16e}
            title="CR 3/16E Pressure Foot for Single Needle"
            price={35}
            del_price={70}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_mt18}
            title="MT-18 Pressure Foot for Single Needle"
            price={12}
            del_price={25}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_p36ln}
            title="P36LN Pressure Foot for Single Needle"
            price={15}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_p36n}
            title="P36N Pressure Foot for Single Needle"
            price={15}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_p69lh_1_8}
            title="P69LH 1/8 Pressure Foot for Single Needle"
            price={15}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_p69rh}
            title="P69RH Pressure Foot for Single Needle"
            price={29}
            del_price={60}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_p127}
            title="P127 Pressure Foot for Single Needle"
            price={29}
            del_price={60}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_p363}
            title="P363 Pressure Foot for Single Needle"
            price={29}
            del_price={60}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_p952}
            title="P952 Pressure Foot for Single Needle"
            price={29}
            del_price={60}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_r141}
            title="R141 Pressure Foot for Single Needle"
            price={199}
            del_price={400}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_sp18}
            title="SP18 Pressure Foot for Single Needle"
            price={35}
            del_price={70}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_t350}
            title="T350 Pressure Foot for Single Needle"
            price={15}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_u192_u193}
            title="U192 - U193 Pressure Foot for Single Needle"
            price={89}
            del_price={150}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link to="/tension" style={{ textDecoration: "none", color: "black" }}>
          <Product
            image={juki_tension}
            title="Tension for Single Needle"
            price={25}
            del_price={50}
            off={50}
            alt="sewing machine tension"
            img_title="Tension for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar-bush"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bush}
            title="Needle Bar Bush"
            price={22}
            del_price={50}
            off={45}
            alt="Needle bar bush"
            img_title="Needle Bar Bush for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar-bush"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bush}
            title="Needle Bar Bush (High Quality)"
            price={59}
            del_price={85}
            off={45}
            alt="Needle bar bush"
            img_title="Needle Bar Bush for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_bar}
            title="Needle Bar for Single Needle"
            price={22}
            del_price={40}
            off={49}
            alt="Needle bar"
            img_title="Needle Bar for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_bar_1}
            title="Needle Bar for Single Needle (High Quality)"
            price={59}
            del_price={80}
            off={49}
            alt="Needle bar"
            img_title="Needle Bar for Single Needle"
          />
        </Link>
        <Link
          to="/bobbin-case-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin_case}
            title="Haya Bobbin Case"
            price={33}
            del_price={60}
            off={49}
            alt="Bobbin Case"
            img_title="Haya Bobbin Case"
          />
        </Link>
        <Link
          to="/oil-pump-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pump}
            title="Oil Pump for Single Needle"
            price={99}
            del_price={120}
            off={22}
            alt="Oil Pump"
            img_title="Oil Pump"
          />
        </Link>
        <Link
          to="/thread-guide-pin-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={head_killi}
            title="Thread Guide Pin for Single Needle"
            price={12}
            del_price={20}
            off={40}
            alt="thread guide pin head killi"
            img_title="Thread Guide Pin"
          />
        </Link>
        <Link
          to="/pressure-foot-lifter-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={chota_lifter}
            title="Pressure Foot Lifter"
            price={5}
            del_price={10}
            off={50}
            alt="thread guide pin head killi"
            img_title="Thread Guide Pin"
          />
        </Link>

        <Link
          to="/head-rest-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={head_rest}
            title="Head Rest"
            price={5}
            del_price={10}
            off={50}
            alt="head rest for sewing machine"
            img_title="Head Rest"
          />
        </Link>
        <Link
          to="/hinges-set-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={hinges}
            title="Hinges Set"
            price={25}
            del_price={35}
            off={49}
            alt="sewing machine hinges set"
            img_title="Hinges Set"
          />
        </Link>
        <Link
          to="/kundi-cover-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={kundi_cover}
            title="Kundi Cover"
            price={15}
            del_price={35}
            off={49}
            alt="Kundi Cover"
            img_title="Kundi Cover"
          />
        </Link>
        <Link
          to="/oil-seal-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={oil_seal}
            title="Oil Seal"
            price={18}
            del_price={35}
            off={49}
            alt="Oil Seal"
            img_title="Thread Guide Pin"
          />
        </Link>
        <Link
          to="/oil-window-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={oil_window}
            title="Oil Window"
            price={5}
            del_price={35}
            off={49}
            alt="Oil Window"
            img_title="Oil Window"
          />
        </Link>
        <Link
          to="/pressure-cap-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_cap}
            title="Pressure Cap"
            price={5}
            del_price={10}
            off={50}
            alt="sewing machine pressure cap"
            img_title="Pressure Cap"
          />
        </Link>
        <Link
          to="/pulley-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pulley}
            title="Pulley"
            price={120}
            del_price={200}
            off={40}
            alt="pulley for sewing machine"
            img_title="Pulley"
          />
        </Link>
        <Link
          to="/Tank-Killi-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={tank_killi}
            title="Tank Killi"
            price={5}
            del_price={10}
            off={50}
            alt="Tank Killi"
            img_title="Tank Killi"
          />
        </Link>
        <Link
          to="/2-hole-thread-guide-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={hole_guide_2}
            title="2 Hole Thread guide"
            price={15}
            del_price={35}
            off={60}
            alt="sewing machine thread guide"
            img_title="Thread Guide 2 Hole"
          />
        </Link>
        <Link
          to="/3-hole-thread-guide-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={hole_guide_3}
            title="3 Hole Thread Guide"
            price={19}
            del_price={35}
            off={49}
            alt="thread guide sewing machine"
            img_title="Thread Guide"
          />
        </Link>
        <Link
          to="/dial-part-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={baram}
            title="Dial Part Baram"
            price={65}
            del_price={100}
            off={49}
            alt="sewing machine baram"
            img_title="Dial part"
          />
        </Link>
        <Link
          to="/lifter-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={big_lifter}
            title="Lifter"
            price={49}
            del_price={100}
            off={49}
            alt="sewing machine lifter"
            img_title="Lifter"
          />
        </Link>
        <Link
          to="/chaal-spring-1-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={chaal_spring}
            title="Chaal Spring"
            price={10}
            del_price={20}
            off={49}
            alt="sewing machine spring"
            img_title="Chaal Spring"
          />
        </Link>
        <Link
          to="/chaal-spring-2-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={chaal_spring_small}
            title="Chaal Spring"
            price={10}
            del_price={20}
            off={49}
            alt="sewing machine spring"
            img_title="Chaal Spring"
          />
        </Link>
        <Link
          to="/folder-adjuster-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={chawanni}
            title="Folder Adjuster (Chawanni)"
            price={49}
            del_price={100}
            off={49}
            alt="sewing machine folder adjuster"
            img_title="folder adjuster"
          />
        </Link>
        <Link
          to="/Dial-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={dial}
            title="Dial (Ghadi)"
            price={10}
            del_price={22}
            off={55}
            alt="thread guide pin head killi"
            img_title="Thread Guide Pin"
          />
        </Link>
        <Link
          to="/Kundi-set-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={kundi}
            title="Kundi Set"
            price={99}
            del_price={190}
            off={49}
            alt="sewing machine kundi"
            img_title="Kundi Set"
          />
        </Link>
        <Link
          to="/thread-guide-left-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={left_guide}
            title="Thread Guide (Left)"
            price={7}
            del_price={15}
            off={49}
            alt="sewing machine thread guide"
            img_title="Thread Guide"
          />
        </Link>
        <Link
          to="/thread-guide-right-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={right_guide}
            title="Thread Guide (Right)"
            price={7}
            del_price={15}
            off={49}
            alt="sewing machine thread guide"
            img_title="Thread Guide"
          />
        </Link>
        <Link
          to="/rubber-joint-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={T}
            title="Rubber Joint For Oil Pump"
            price={7}
            del_price={15}
            off={49}
            alt="sewing machine rubber joint"
            img_title="rubber joint"
          />
        </Link>
        <Link
          to="/slide-plate-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={slide}
            title="Slide Plate"
            price={20}
            del_price={35}
            off={49}
            alt="sewing machine slide plate"
            img_title="Slide Plate"
          />
        </Link>
        <Link
          to="/spring-rod-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={spring_rod}
            title="Pressure Foot Spring + Rod"
            price={12}
            del_price={15}
            off={35}
            alt="sewing machine spring rod"
            img_title="Spring Rod"
          />
        </Link>
        <Link
          to="/stopper-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={stopper}
            title="Stopper"
            price={19}
            del_price={35}
            off={49}
            alt="sewing machine stopper"
            img_title="Stopper"
          />
        </Link>
        <Link
          to="/ubt-tension-for-lockstitch-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={ubt_tension}
            title="Thread Tension for UBT"
            price={29}
            del_price={35}
            off={49}
            alt="sewing machine tension"
            img_title="Thread Guide Pin"
          />
        </Link>
      </div>
    </div>
  );
};

export default SingleNeedle;
