import React from "react";
import { Link } from "react-router-dom";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";

// images
import gauge_set from "../../images/m700-spares/m700-gauge-set.jpg";
import looper1 from "../../images/m700-spares/204072-looper.jpg";
import looper2 from "../../images/m700-spares/204702-looper.jpg";
// import m700_feed_dog from "../../images/m700-spares/m700-feed-dog.jpg";
import m700_needle_clamp from "../../images/m700-spares/m700-needle-clamp.jpg";
import m700_needle_plate from "../../images/m700-spares/m700-needle-plate.jpg";
import m700_pressure_foot from "../../images/m700-spares/m700-pressure-foot.jpg";

const M700Overlock = () => {
  return (
    <div>
      <h4 className="sub-title">M700 Overlock Parts</h4>
      <div className="spare-parts-categories">
        <Link
          to="/m700-gauge-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={gauge_set}
            title="M-700 Gauge Set"
            price={450}
            del_price={700}
            off={39}
            alt="m700 guage set"
            img_title="M-700 Gauge Set"
          />
        </Link>
        <Link
          to="/m700-looper-204072"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper1}
            title="M-700 Looper 204072"
            price={45}
            del_price={70}
            off={35}
            alt="204072 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-looper-204702"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper2}
            title="M-700 Looper 204702"
            price={45}
            del_price={70}
            off={35}
            alt="204702 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_plate}
            title="M-700 Needle Plate"
            price={70}
            del_price={110}
            off={28}
            alt="m700 needle plate"
            img_title="M-700 Needle Plate"
          />
        </Link>
        <Link
          to="/m700-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_pressure_foot}
            title="M-700 Pressure Foot"
            price={60}
            del_price={85}
            off={21}
            alt="m700 pressure foot"
            img_title="M-700 Pressure Foot"
          />
        </Link>
        <Link
          to="/m700-needle-clamp"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_clamp}
            title="M-700 Needle Clamp"
            price={70}
            del_price={110}
            off={39}
            alt="m700 needle clamp"
            img_title="M-700 Needle Clamp"
          />
        </Link>
      </div>
    </div>
  );
};

export default M700Overlock;
