import React from "react";
import SEO from "../../../components/SEO";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import breostar_110 from "../../../images/cutting-machine/breostar-8.jpg"

const Breostar8 = () => {
  return (
    <div>
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
<SEO
title="Breostar 8 Inch Cloth Cutting Machine"
description="BREOSTAR 8 inch CLOTH CUTTING MACHINE. This heavy-duty electric Single Phase (220V/50 Hz) (250 W) rotary cutter will cut up to 15 layers of fabric. Grinding Wheel is attached next to the blade and the blade can easily be sharpened with a press of a button. ( Refer to the instruction manual for more info.). It can cut cotton, woolen, lines, silk, chemical fiber, etc. Freely cutting, stable running, easily used.Included Components - Cutting machine, power-cord.It is a high-performance circular knife cutting machine aimed for a super fine cut from a single piece to the wide variety of works. "
name="Sewix"
type="website"
url="https://www.sewix.in/breostar-8inch-cloth-cutting-machine"
main_image={breostar_110}
keywords="Breostar cutting machine, 8 inch fabric cutting machine, 8 inch textile cutting machine, 8 inch cloth cutter machine, 8 inch electric cutting machine, 8 inch precision cutting machine, 8 inch manual cutting machine, 8 inch rotary cutting machine, 8 inch flatbed cutting machine, 8 inch knife cutting machine, 8 inch ultrasonic cutting machine, 8 inch blade cutting machine, 8 inch computerized cutting machine, 8 inch programmable cutting machine, 8 inch industrial cutting machine, 8 inch automatic cutting machine, 8 inch laser cutting machine, 8 inch CNC cutting machine, 8 inch waterjet cutting machine, 8 inch plasma cutting machine, 8 inch scissor cutting machine, 8 inch heavy duty cutting machine, 8 inch portable cutting machine." />
      <ProductPage
        image={breostar_110}
        alt="Breostar 8 Inch Cloth Cutting Machine"
        img_title="Breostar 8 Inch Cloth Cutting Machine"
        title="Breostar 8 Inch Cloth Cutting Machine"
        price={14999}
        del_price={25000}
        discount={32}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Cutting Machine, 2 spring"
        Highlight_1="Heavy duty"
        Highlight_2="Full metal body"
        Highlight_3="Heavy Duty"
        Highlight_4="Excellent Quality"
        key_features="BREOSTAR 8 inch CLOTH CUTTING MACHINE. This heavy-duty electric Single Phase (220V/50 Hz) (250 W) rotary cutter will cut up to 15 layers of fabric. Grinding Wheel is attached next to the blade and the blade can easily be sharpened with a press of a button. ( Refer to the instruction manual for more info.). It can cut cotton, woolen, lines, silk, chemical fiber, etc. Freely cutting, stable running, easily used.Included Components - Cutting machine, power-cord.It is a high-performance circular knife cutting machine aimed for a super fine cut from a single piece to the wide variety of works. ."
      />
      <Specs brand_name="Breostar"
      model_name="8 inch"
      color="Silver & Blue"
      type="Heavy Duty"
      material="Raw Metal"
      speed="-"
      shape="Round"
       />
    </div>
  );
};

export default Breostar8;
