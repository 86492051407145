import React from "react";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";
import { Link } from "react-router-dom";

// IMAGES
import shuttle from "../../images/shuttle.jpeg";
import shuttle781 from '../../images/spare parts/juki-781/shuttle.jpg'


const HookSetsCat = () => {
  return (
    <div className="spare-parts-categories">
      <Link
        to="/yoke-hook-set"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={shuttle}
          alt="hook set Shuttle"
          img_title="Yoke Shuttle"
          title="Yoke Shuttle For Lockstitch Machine"
          price={199}
          del_price={350}
          off={45}
        />
      </Link>
      <Link
          to="/juki-butttonhole-shuttle"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={shuttle781}
            title="Rnd Original Shuttle / Hook Set"
            price={1100}
            del_price={1900}
            off={39}
            alt="juki buttonhole shuttle"
            img_title="Rnd Hook Set"
          />
        </Link>
    </div>
  );
};

export default HookSetsCat;
