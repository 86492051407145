import React from "react";
import SEO from "../components/SEO";
import "../styles/HomePage.css";
// import Hero from "../components/Hero";
import COD from "../components/COD";
import Product from "../components/Product";
import ViewAll from "../components/ViewAll";
import ProductCategory from "../components/ProductCategory";
import HeroDesk from "../components/HeroDesk";
import { Link } from "react-router-dom";
// import Slider from "../components/Slider";

// Images
// import u1_usha from "../images/Usha-hand/usha1.jpg";
import servo from "../images/esda/esda-new-1.jpg";
import pcb from "../images/pcb.jpg";
import s1_spare from "../images/shuttle.jpeg";
import press from "../images/silti-press/silti-1.jpg";
// import bobbin_binder from "../images/bobbin-binder.jpg";
// import scissor from "../images/scissor.jpg";
import cutting_machine from "../images/cutting-machine.jpg";
import spotting_gun from "../images/spotting-gun.jpg";
import esda_1 from "../images/servo-motor/esda-new-1.jpg";
import jack_servo from "../images/servo-motor/jack-servo.jpg";
import esda_dd from "../images/servo-motor/esda-dd.jpg";
// import akari from "../images/motor/akari-servo/akari-0.jpg";
import messer_motor from "../images/servo-motor/messer.jpg";
import hmc_motor from "../images/servo-motor/hmc-motor.jpg";
import jack_f4_pcb from "../images/pcb/jack-f4-pcb.jpg";
import jack_a2_pcb from "../images/pcb/jack-a2-pcb.jpg";
import jack_804d_pcb from "../images/pcb/jack-804d-pcb.jpg";
import jack_9100bp from "../images/pcb/jack-9100bp.jpg";
// import jack_a2s_pcb from "../images/pcb/jack-a2s-pcb.jpg";
import esda_pcb from "../images/pcb/esda-pcb.jpg";

// import xnf15n60t from "../images/component/xnf15n60t.jpg";
// import ir2136s from "../images/component/ir2136s.jpg";
// import mm32spin from "../images/component/mm32spin.jpg";
// import fan73892 from "../images/component/fan73892.jpg";

import esda_steam from "../images/steam-press/esda-steam.jpg";
import silti_96 from "../images/steam-press/silit-96.jpg";
import silti_300lb from "../images/steam-press/silti-300lb.jpg";
import silver_star1 from "../images/steam-press/silver-star-2050.jpg";
import industrial_iron from "../images/steam-press/industrial-iron.jpg";

import breostar_110 from "../images/cutting-machine/breostar-110.jpg";
// import breostar_125 from "../images/cutting-machine/breostar-125.jpg";
import taking_65 from "../images/cutting-machine/taking-65.jpg";
import lippu_8 from "../images/cutting-machine/lipu-8.jpg";
import breostar_8 from "../images/cutting-machine/breostar-8.jpg";
import cupid_110 from "../images/cutting-machine/cupid-110.jpg";

// import jumbo_hook from "../images/hook-set/jumbo-hook-set.jpg";
// import kaaj_hook from "../images/hook-set/kaaj-hook.jpg";
// import bartack_hook from "../images/hook-set/bartack-hook.jpg";
// import double_needle_hook from "../images/hook-set/double-needle-hook.jpg";

import needles from "../images/spare parts/spare-cat/groz-db1.jpg";
import juki_boot from "../images/spare parts/single-needle/juki-boot.jpg";
import juki_plate from "../images/spare parts/single-needle/juki-plate.jpg";
import folders from "../images/spare parts/spare-cat/folders.jpg";
import bobbin from "../images/spare parts/spare-cat/bobbin.jpg";
import thread_guide from "../images/spare parts/spare-cat/thread-guide.jpg";
import gauge_sets from "../images/gauge-set-feed-off.jpg";

import feed_dog from "../images/spare parts/single-needle/feed-dog-htype.jpg";
import bobbin_case from "../images/spare parts/single-needle/bobbin-case.jpg";
import needle_bar from "../images/spare parts/single-needle/needle-bar.jpg";

import juki_tension from "../images/spare parts/single-needle/tension-juki.jpg";
import bush from "../images/spare parts/single-needle/bush.jpg";
// import number from "../images/number.jpg";
import gauge_set from "../images/m700-spares/m700-gauge-set.jpg";
import looper1 from "../images/m700-spares/204072-looper.jpg";
import looper2 from "../images/m700-spares/204702-looper.jpg";
import m700_needle_clamp from "../images/m700-spares/m700-needle-clamp.jpg";
import m700_needle_plate from "../images/m700-spares/m700-needle-plate.jpg";
import m700_pressure_foot from "../images/m700-spares/m700-pressure-foot.jpg";
import shuttle781 from "../images/spare parts/juki-781/shuttle.jpg";
import bobbin_case_781 from "../images/spare parts/juki-781/bobbin-case.jpg";
import bobbin781 from "../images/spare parts/juki-781/bobbin.jpg";
import pressure_foot_781 from "../images/spare parts/juki-781/pressure-foot.jpg";
import scissor781 from "../images/spare parts/juki-781/scissor.jpg";
import tension1_781 from "../images/spare parts/juki-781/tension1.jpg";
import CategorySpare from "../components/CategorySpare";
import organ from "../images/spare parts/single-needle/organ-db1.jpg";
import flying_tiger from "../images/spare parts/single-needle/flying-tiger-db1.jpg";
import white_crane from "../images/spare parts/single-needle/white-crane-db1.jpg";
import groz from "../images/spare parts/single-needle/groz-db1.jpg";
import schmetz_dc1 from "../images/needle/schmetz-dc1.jpg";

import heromain from "../../src/images/heropage.jpg";

const Home = () => {
  return (
    <>
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
      <SEO
        title="Buy Sewing Machine Parts & Accessories Online - Sewix.in"
        description="Buy genuine sewing machine motor, cloth cutting machine, steam press iron, sewing machine spare parts, juki parts, jack parts, kansai special parts, siruba, brother, overlock, pcb boards, spotting gun etc."
        name="Sewix"
        type="website"
        url="https://www.sewix.in/"
        main_image={heromain}
        keywords="silai machine	,sewing machine	,sewing,tailoring machine	,usha sewing machine	,stitching machine	,cloth cutting machine	,fabric cutting machine ,cloth cutter	,steam press		,steam press iron	,sewing machine motor	,sewing machine parts	,bobbin case ,sewing machine motor price ,presser foot for sewing machine , sewix, sewix.in ,
    www.sewix.in	 			"
      />
      <div className="home-desk">
        <HeroDesk />
      </div>
      <div>
        <COD />
      </div>
      {/* <div className="home-hero">
        <Hero />
      </div> */}

      {/* <Slider /> */}
      {/* PRODUCT CATEGORY ....................................... */}
      <ViewAll name="Category" link="/Categories" />
      <div className="product-category">
        {/* <Link
          to="/domestic-sewing-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory
            image={u1_usha}
            title="Sewing Machines"
            alt="usha sewing machine"
            img_title="Domestic Sewing Machine"
          />
        </Link> */}
        <Link
          to="/sewing-machine-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory
            image={servo}
            title="Servo Motor"
            alt="sewing machine motor"
            img_title="Power Saving Motor"
          />
        </Link>
        <Link
          to="/pcb-board"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory
            image={pcb}
            title="PCB Boards"
            alt="sewing machine Pcb board"
            img_title="pcb board"
          />
        </Link>
        <Link
          to="/sewing-machine-parts"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory
            image={s1_spare}
            title="Parts"
            alt="sewing machine shuttle"
            img_title="Sewing Machine Parts"
          />
        </Link>
        <Link
          to="/steam-press-iron"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory
            image={press}
            title="Steam Press"
            alt="steam press"
            img_title="steam press"
          />
        </Link>
        <Link
          to="/cloth-cutting-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory
            image={cutting_machine}
            title="Cutting Machine"
            alt="cloth cutting machine"
            img_title="Cutting Machine"
          />
        </Link>
        <Link
          to="/sewing-machine-parts"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory
            image={spotting_gun}
            title="Spotting Gun"
            alt="spotting gun"
            img_title="Spotting Gun"
          />
        </Link>
      </div>
      {/* MOTORS ............................................ */}
      <ViewAll name="Motors" link="sewing-machine-motor" />
      <div className="products products-motor">
        <Link
          to="esda-power-saving-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={esda_1}
            title="Esda Power Saving Motor"
            price={2999}
            del_price={4500}
            off={32}
            alt="sewing machine motor"
            img_title="Esda Power Saving Motor"
          />
        </Link>
        <Link
          to="messer-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={messer_motor}
            title="Messer Servo Motor"
            price={2799}
            del_price={4000}
            off={28}
            alt="sewing machine motor"
            img_title="Messer Servo Motor"
          />
        </Link>
        <Link
          to="/hmc-servo-motor-new"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={hmc_motor}
            title="HMC Servo Motor"
            price={3099}
            del_price={4500}
            off={32}
            alt="sewing machine motor"
            img_title="hmc Servo Motor"
          />
        </Link>
        <Link
          to="esda-direct-drive-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={esda_dd}
            title="Esda Direct Drive Motor"
            price={4299}
            del_price={5500}
            off={27}
            alt="sewing machine motor"
            img_title="Esda Direct Drive Servo Motor"
          />
        </Link>

        <div className="hide">
          <Link
            to="jack-servo-motor"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={jack_servo}
              title="Jack Servo Motor"
              price={4299}
              del_price={5000}
              off={25}
              alt="sewing machine motor"
              img_title="Jack Servo Motor"
            />
          </Link>
        </div>
      </div>
      {/* PCB Board ......................................... */}
      <ViewAll name="PCB Board" link="pcb-board" />
      <div className="products products-motor">
        <Link
          to="Jack-f4-pcb"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={jack_f4_pcb}
            title="Jack F4 PCB Board"
            price={2299}
            del_price={5500}
            off={55}
            alt="jack f4 pcb direct drive"
            img_title="Jack F4 PCB Board"
          />
        </Link>
        <Link
          to="/esda-servo-motor-pcb"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={esda_pcb}
            title="Esda Servo Motor PCB Board"
            price={1599}
            del_price={2399}
            off={32}
            alt="Esda Servo Motor PCB Board"
            img_title="Esda Servo Motor PCB Board"
          />
        </Link>
        <Link
          to="jack-9100bp-pcb"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={jack_9100bp}
            title="Jack 9100b (New Model) PCB Board"
            price={3499}
            del_price={6000}
            off={52}
            alt="Jack 9100b PCB Board"
            img_title="Jack 9100b PCB Board"
          />
        </Link>
        <Link
          to="jack-a2-pcb"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={jack_a2_pcb}
            title="Jack A2 PCB Board"
            price={3299}
            del_price={5000}
            off={25}
            alt="Jack A2 PCB Board"
            img_title="Jack A2 PCB Board"
          />
        </Link>

        <div className="hide">
          <Link
            to="jack-804d-pcb"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={jack_804d_pcb}
              title="Jack 804D PCB"
              price={3999}
              del_price={6000}
              off={27}
              alt="Jack 804D PCB"
              img_title="Jack 804D PCB"
            />
          </Link>
        </div>
      </div>

      {/* ELECTRONICS COMPONENTS */}
      {/* <ViewAll name="Motors" link="sewing-machine-motor" />
      <div className="products products-motor">
        <Link
          to="esda-power-saving-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={xnf15n60t}
            title="15N60T IGBT"
            price={59}
            del_price={99}
            off={32}
            alt="15n60t igbt"
            img_title="15N60T IGBT"
          />
        </Link>
        <Link
          to="akari-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={ir2136s}
            title="IR2136s Gate Driver"
            price={149}
            del_price={299}
            off={48}
            alt="IR2136s Gate Driver"
            img_title="IR2136s Gate Driver"
          />
        </Link>
        <Link
          to="/hmc-servo-motor-new"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={mm32spin}
            title="mm32spin 48pin Microcontroller (Programmed)"
            price={399}
            del_price={899}
            off={49}
          />
        </Link>
        <Link
          to="vicco-priya-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={fan73892}
            title="fan73892 Gate Driver"
            price={199}
            del_price={300}
            off={35}
            alt="fan73892"
            img_title="fan73892"
          />
        </Link>

        <div className="hide">
          <Link
            to="hmc-servo-motor"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={mm32spin}
              title="mm32spin 32pin Microcontroller (Programmed)"
              price={399}
              del_price={450}
              off={27}
              alt="mm32spin 32pin Microcontroller (Programmed)"
              img_title="mm32spin 32pin Microcontroller (Programmed)"
            />
          </Link>
        </div>
      </div>  */}
      {/* <img className="number-specific" src={number} alt="" /> */}
      {/* SEWING PARTS CATEGORY ................................ */}
      <ViewAll name="Sewing Parts" link="sewing-machine-parts" />
      <div className="spare-list">
        <Link
          to="/hook-sets"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CategorySpare
            image={s1_spare}
            alt="shuttle"
            img_title="Shuttle"
            title="Shuttle"
          />
        </Link>
        <Link
          to="/tension-sets"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CategorySpare
            image={juki_tension}
            alt="sewing machine tension"
            img_title="Tension"
            title="Tension"
          />
        </Link>
        <Link to="/needles" style={{ textDecoration: "none", color: "black" }}>
          <CategorySpare
            image={needles}
            alt="sewing machine needle"
            img_title="Needle"
            title="Needle"
          />
        </Link>
        <Link to="/looper" style={{ textDecoration: "none", color: "black" }}>
          <CategorySpare
            image={looper2}
            alt="sewing machine looper"
            img_title="Looper"
            title="Looper"
          />
        </Link>
        <Link to="/feed-dog" style={{ textDecoration: "none", color: "black" }}>
          <CategorySpare
            image={feed_dog}
            alt="Feed Dog"
            img_title="Feed Dog"
            title="FeedDog"
          />
        </Link>
        <Link to="/folder" style={{ textDecoration: "none", color: "black" }}>
          <CategorySpare
            image={folders}
            alt="sewing machine folder"
            img_title="Folder"
            title="Folder"
          />
        </Link>
        <Link to="/bobbin" style={{ textDecoration: "none", color: "black" }}>
          <CategorySpare
            image={bobbin}
            alt="sewing machine bobbin"
            img_title="Bobbin"
            title="Bobbin"
          />
        </Link>
        <Link
          to="/thread guides"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CategorySpare
            image={thread_guide}
            alt="sewing machine thread guide"
            img_title="Thread Guide"
            title="Guide"
          />
        </Link>
        <Link
          to="/gauge-sets"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CategorySpare
            image={gauge_sets}
            alt="Gauge Set"
            img_title="Gauge Set"
            title="Gauge Set"
          />
        </Link>
        <Link
          to="/pressure-foots"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CategorySpare
            image={juki_boot}
            alt="Pressure Foot"
            img_title="Pressure Foot"
            title="Pressure Foot"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CategorySpare
            image={juki_plate}
            alt="sewing machine needle plate"
            img_title="Needle Plate"
            title="Needle Plate"
          />
        </Link>
        <Link
          to="/bobbin-case"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CategorySpare
            image={bobbin_case}
            alt="Bobbin Case"
            img_title="Bobbin Case"
            title="Bobbin Case"
          />
        </Link>
      </div>

      {/* STEAM PRESS */}
      <ViewAll name="Steam Press" link="steam-press-iron" />
      <div className="products products-motor">
        <Link
          to="esda-steam-press-iron"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={esda_steam}
            title="Esda ES-300L Steam Press"
            price={2299}
            del_price={3500}
            off={32}
            alt="Steam Press"
            img_title="Esda ES-300L Steam Press"
          />
        </Link>
        <Link
          to="silti-st96-steam-press-iron"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={silti_96}
            title="Silti ST-96 Steam Press"
            price={2499}
            del_price={4000}
            off={48}
            alt="steam press"
            img_title="Silti ST-96 Steam Press"
          />
        </Link>
        <Link
          to="/silver-star-1-steam-press-iron"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={silver_star1}
            title="Silver Star Steam Press"
            price={2599}
            del_price={4000}
            off={49}
          />
        </Link>
        <Link
          to="industrial-iron-steam-press-iron"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={industrial_iron}
            title="Industrial Iron"
            price={2299}
            del_price={4000}
            off={35}
            alt="Industrial iron"
            img_title="Industrial Iron"
          />
        </Link>

        <div className="hide">
          <Link
            to="silti-es300lb-steam-press-iron"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={silti_300lb}
              title="Silti ES-300L-B Steam Press"
              price={399}
              del_price={450}
              off={27}
              alt="Steam Press"
              img_title="Silti ES-300L-B Steam Press"
            />
          </Link>
        </div>
      </div>

      {/* CUTTING MACHINE */}
      <ViewAll name="Cutting Machine" link="/cloth-cutting-machine" />
      <div className="products products-motor">
        <Link
          to="/breostar-110mm-cloth-cutting-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={breostar_110}
            title="Breostar 110mm Cutting Machine"
            price={2599}
            del_price={4000}
            off={32}
            alt="cloth cutting machine"
            img_title="Breostar 110mm Cutting Machine"
          />
        </Link>
        <Link
          to="/cupid-110mm-cloth-cutting-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={cupid_110}
            title="Cupid 110mm Cutting Machine"
            price={4899}
            del_price={6000}
            off={32}
            alt="cloth cutting machine"
            img_title="Cupid 110mm Cutting Machine"
          />
        </Link>
        <Link
          to="/taking-65mm-cloth-cutting-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={taking_65}
            title="Taking 65mm Cloth Cutting Machine"
            price={3299}
            del_price={5500}
            off={49}
            alt="cloth cutting machine"
            img_title="Taking 65mm Cutting Machine"
          />
        </Link>
        <Link
          to="lippu-8inch-cloth-cutting-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={lippu_8}
            title="Lippu 8 inch Cutting Machine"
            price={13999}
            del_price={20000}
            off={35}
            alt="cloth cutting machine"
            img_title="Lippu 8 inch Cutting Machine"
          />
        </Link>

        <div className="hide">
          <Link
            to="breostar-8inch-cloth-cutting-machine"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={breostar_8}
              title="Breostar 8 inch Cloth Cutting Machine"
              price={14999}
              del_price={25000}
              off={27}
              alt="cloth cutting machine"
              img_title="Breostar 8 inch Cloth Cutting Machine"
            />
          </Link>
        </div>
      </div>

      {/* SEWING PARTS .......................................... */}

      <ViewAll
        name="Parts for Lockstitch Single Needle"
        link="/lockstitch-sewing-machine-parts"
      />
      <div className="parts-product">
        <Link
          to="/yoke-hook-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={s1_spare}
            title="Hook Set Shuttle"
            price={199}
            del_price={300}
            off={38}
            alt="sewing machine hook set"
            img_title="Hook Set for Lockstitch Machine"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_boot}
            title="P351 Pressure Foot for Single Needle"
            price={15}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_plate}
            title="E-16 Needle Plate for Single Needle"
            price={19}
            del_price={30}
            off={47}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link to="/tension" style={{ textDecoration: "none", color: "black" }}>
          <Product
            image={juki_tension}
            title="Tension for Single Needle"
            price={25}
            del_price={45}
            off={43}
            alt="sewing machine tension"
            img_title="Tension for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar-bush"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bush}
            title="Needle Bar Bush"
            price={22}
            del_price={50}
            off={60}
            alt="Needle bar bush"
            img_title="Needle Bar Bush for Single Needle"
          />
        </Link>
        <div className="hide-spare">
          <Link
            to="/needle-bar"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={needle_bar}
              title="Needle Bar for Single Needle"
              price={22}
              del_price={35}
              off={49}
              alt="Needle bar"
              img_title="Needle Bar for Single Needle"
            />
          </Link>
        </div>
      </div>

      {/* NEEDLES */}

      <ViewAll name="Sewing Needles" link="/needles" />
      <div className="parts-product">
        <Link
          to="/groz-beckert-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={groz}
            title="Groz-Beckert Needle DBX1"
            price={55}
            del_price={80}
            off={42}
            alt="groz beckart needle"
            img_title="Groz Beckart"
          />
        </Link>
        <Link
          to="/flying-tiger-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={flying_tiger}
            title="Flying Tiger Needle DBX1"
            price={15}
            del_price={20}
            off={35}
            alt="flying tiger needle"
            img_title="Flying Tiger Needle"
          />
        </Link>
        <Link
          to="/white-crane-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={white_crane}
            title="White Crane Needle DBX1"
            price={22}
            del_price={30}
            off={35}
            alt="white crane needle"
            img_title="White Crane Needle"
          />
        </Link>
        <Link
          to="/organ-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={organ}
            title="Organ Needle DBX1"
            price={75}
            del_price={90}
            off={20}
            alt="organ needle"
            img_title="Organ Needle"
          />
        </Link>
        <Link
          to="/schmetz-needle-dc1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={schmetz_dc1}
            title="Schmetz DCx1"
            price={55}
            del_price={80}
            off={32}
            alt="schmetz needle"
            img_title="Schmetz Needle"
          />
        </Link>
      </div>
      <ViewAll name="M-700 Overlock Parts" link="/m700-overlock-parts" />
      <div className="parts-product">
        <Link
          to="/m700-gauge-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={gauge_set}
            title="M-700 Gauge Set"
            price={350}
            del_price={700}
            off={50}
            alt="m700 guage set"
            img_title="M-700 Gauge Set"
          />
        </Link>
        <Link
          to="/m700-looper-204072"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper1}
            title="M-700 Looper 204072"
            price={60}
            del_price={80}
            off={38}
            alt="204072 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-looper-204702"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper2}
            title="M-700 Looper 204702"
            price={45}
            del_price={70}
            off={35}
            alt="204702 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_plate}
            title="M-700 Needle Plate"
            price={80}
            del_price={110}
            off={28}
            alt="m700 needle plate"
            img_title="M-700 Needle Plate"
          />
        </Link>
        <Link
          to="/m700-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_pressure_foot}
            title="M-700 Pressure Foot"
            price={70}
            del_price={85}
            off={21}
            alt="m700 pressure foot"
            img_title="M-700 Pressure Foot"
          />
        </Link>
        <div className="hide-spare">
          <Link
            to="/m700-needle-clamp"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={m700_needle_clamp}
              title="M-700 Needle Clamp"
              price={80}
              del_price={110}
              off={39}
              alt="m700 needle clamp"
              img_title="M-700 Needle Clamp"
            />
          </Link>
        </div>
      </div>

      {/* Juki 781 Spares  */}
      <ViewAll name="Juki Buttonhole Parts" link="/juki-buttonhole-parts" />
      <div className="parts-product">
        <Link
          to="/juki-butttonhole-shuttle"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={shuttle781}
            title="Rnd Original Shuttle / Hook Set"
            price={1299}
            del_price={1900}
            off={42}
            alt="juki buttonhole shuttle"
            img_title="Rnd Hook Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin-case"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin_case_781}
            title="Haya Bobbin Case"
            price={380}
            del_price={600}
            off={44}
            alt="haya bobbin case"
            img_title="Haya Bobbin Case"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin781}
            title="Bobbin"
            price={49}
            del_price={70}
            off={35}
            alt="juki bobbin"
            img_title="Bobbin"
          />
        </Link>
        <Link
          to="/juki-butttonhole-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_781}
            title="Pressure Foot"
            price={290}
            del_price={350}
            off={28}
            alt="pressure foot"
            img_title="Pressure Foot"
          />
        </Link>
        <Link
          to="/juki-butttonhole-scissor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={scissor781}
            title="Scissor Set (H-Strong)"
            price={280}
            del_price={350}
            off={28}
            alt="juki buttonhole scissor"
            img_title="Scissor Set"
          />
        </Link>
        <div className="hide-spare">
          <Link
            to="/juki-butttonhole-lower-tension"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Product
              image={tension1_781}
              title="Tension Set (Lower)"
              price={65}
              del_price={80}
              off={39}
              alt="tension set"
              img_title="Tension Set"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
