import React from "react";
import Product from "../components/Product";
import ViewAll from "../components/ViewAll";
import { Link } from "react-router-dom";

// Images
// Images
import esda_1 from "../images/esda/esda-new-1.jpg";
import vicco_1 from "../images/vicco-priya/vicco-priya-1-new.jpg";
import hmc_1 from "../images/hmc/hmc-1-new.jpg";
// import breostar from "../images/breostar-motor/breostar2.jpg";
import s1_spare from "../images/shuttle.jpeg";

// import shuttle from "../images/spare parts/hook-sets.jpg";
// import boot from "../images/spare parts/pressure-foot.jpg";
// import tension from "../images/spare parts/tension.jpg";
// import needle_plate from "../images/spare parts/needle-plates.jpg";
// import feed_dog from "../images/spare parts/feed-dog.png";
// import bobbin_case from "../images/spare parts/bobbin-case.png";
import bush from "../images/spare parts/single-needle/bush.jpg";
import juki_boot from "../images/spare parts/single-needle/juki-boot.jpg";
import juki_plate from "../images/spare parts/single-needle/juki-plate.jpg";
import needle_bar from "../images/spare parts/single-needle/needle-bar.jpg";

import juki_tension from "../images/spare parts/single-needle/tension-juki.jpg";
// import number from "../images/number.jpg";
import gauge_set from "../images/m700-spares/m700-gauge-set.jpg";
import looper1 from "../images/m700-spares/204072-looper.jpg";
import looper2 from "../images/m700-spares/204702-looper.jpg";
// import m700_feed_dog from "../images/m700-spares/m700-feed-dog.jpg";
import m700_needle_clamp from "../images/m700-spares/m700-needle-clamp.jpg";
import m700_needle_plate from "../images/m700-spares/m700-needle-plate.jpg";
import m700_pressure_foot from "../images/m700-spares/m700-pressure-foot.jpg";
import shuttle781 from "../images/spare parts/juki-781/shuttle.jpg";
import bobbin_case_781 from "../images/spare parts/juki-781/bobbin-case.jpg";
import bobbin781 from "../images/spare parts/juki-781/bobbin.jpg";
import pressure_foot_781 from "../images/spare parts/juki-781/pressure-foot.jpg";
import scissor781 from "../images/spare parts/juki-781/scissor.jpg";
import tension1_781 from "../images/spare parts/juki-781/tension1.jpg";

const ProductsPage = () => {
  return (
    <>
      {/* MOTORS  */}
      <ViewAll name="Motors" link="sewing-machine-motor" />
      <div className="products">
        <Link
          to="esda-power-saving-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={esda_1}
            title="Esda Power Saving Motor"
            price={2999}
            del_price={4500}
            off={32}
            alt="sewing machine motor"
            img_title="Esda Power Saving Motor"
          />
        </Link>
        <Link
          to="vicco-priya-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={vicco_1}
            title="Vicco Priya Servo Motor"
            price={3799}
            del_price={5000}
            off={23}
            alt="sewing machine motor"
            img_title="Vicco Priya Servo Motor"
          />
        </Link>
        <Link
          to="hmc-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={hmc_1}
            title="HMC Servo Motor"
            price={3499}
            del_price={4500}
            off={12}
            alt="sewing machine motor"
            img_title="HMC Servo Motor"
          />
        </Link>
        <Link
          to="breostar-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            // image={breostar}
            title="Breostar Servo Motor"
            price={2999}
            del_price={4000}
            off={15}
            alt="sewing machine motor"
            img_title="Breostar Servo Motor"
          />
        </Link>
      </div>
      <ViewAll
        name="Parts for Lockstitch Single Needle"
        link="/lockstitch-sewing-machine-parts"
      />
      <div className="parts-product">
        <Link
          to="/yoke-hook-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={s1_spare}
            title="Hook Set Shuttle"
            price={199}
            del_price={300}
            off={38}
            alt="sewing machine hook set"
            img_title="Hook Set for Lockstitch Machine"
          />
        </Link>
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_boot}
            title="Pressure Foot for Single Needle"
            price={14}
            del_price={30}
            off={50}
            alt="pressure foot"
            img_title="Pressure Foot for Single Needle"
          />
        </Link>
        <Link
          to="/needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={juki_plate}
            title="Needle Plate for Single Needle"
            price={17}
            del_price={30}
            off={50}
            alt="needle plate"
            img_title="Needle Plate for Single Needle"
          />
        </Link>
        <Link to="/tension" style={{ textDecoration: "none", color: "black" }}>
          <Product
            image={juki_tension}
            title="Tension for Single Needle"
            price={20}
            del_price={45}
            off={43}
            alt="sewing machine tension"
            img_title="Tension for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar-bush"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bush}
            title="Needle Bar Bush (High Quality)"
            price={50}
            del_price={85}
            off={45}
            alt="Needle bar bush"
            img_title="Needle Bar Bush for Single Needle"
          />
        </Link>
        <Link
          to="/needle-bar"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_bar}
            title="Needle Bar for Single Needle"
            price={20}
            del_price={35}
            off={49}
            alt="Needle bar"
            img_title="Needle Bar for Single Needle"
          />
        </Link>
      </div>
      <ViewAll name="M-700 Overlock Parts" link="/m700-overlock-parts" />
      <div className="parts-product">
        <Link
          to="/m700-gauge-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={gauge_set}
            title="M-700 Gauge Set"
            price={350}
            del_price={700}
            off={39}
            alt="m700 guage set"
            img_title="M-700 Gauge Set"
          />
        </Link>
        <Link
          to="/m700-looper-204072"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper1}
            title="M-700 Looper 204072"
            price={50}
            del_price={70}
            off={35}
            alt="204072 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-looper-204702"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper2}
            title="M-700 Looper 204702"
            price={45}
            del_price={70}
            off={35}
            alt="204702 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_plate}
            title="M-700 Needle Plate"
            price={70}
            del_price={110}
            off={28}
            alt="m700 needle plate"
            img_title="M-700 Needle Plate"
          />
        </Link>
        <Link
          to="/m700-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_pressure_foot}
            title="M-700 Pressure Foot"
            price={60}
            del_price={85}
            off={21}
            alt="m700 pressure foot"
            img_title="M-700 Pressure Foot"
          />
        </Link>
        <Link
          to="/m700-needle-clamp"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_clamp}
            title="M-700 Needle Clamp"
            price={70}
            del_price={110}
            off={39}
            alt="m700 needle clamp"
            img_title="M-700 Needle Clamp"
          />
        </Link>
      </div>
      {/* Juki 781 Spares  */}
      <ViewAll name="Juki Buttonhole Parts" link="/juki-buttonhole-parts" />
      <div className="parts-product">
        <Link
          to="/juki-butttonhole-shuttle"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={shuttle781}
            title="Rnd Original Shuttle / Hook Set"
            price={1100}
            del_price={700}
            off={39}
            alt="juki buttonhole shuttle"
            img_title="Rnd Hook Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin-case"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin_case_781}
            title="Haya Bobbin Case"
            price={350}
            del_price={400}
            off={23}
            alt="haya bobbin case"
            img_title="Haya Bobbin Case"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin781}
            title="Bobbin"
            price={40}
            del_price={70}
            off={65}
            alt="juki bobbin"
            img_title="Bobbin"
          />
        </Link>
        <Link
          to="/juki-butttonhole-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_781}
            title="Pressure Foot"
            price={270}
            del_price={350}
            off={28}
            alt="pressure foot"
            img_title="Pressure Foot"
          />
        </Link>
        <Link
          to="/juki-butttonhole-scissor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={scissor781}
            title="Scissor Set"
            price={250}
            del_price={350}
            off={28}
            alt="juki buttonhole scissor"
            img_title="Scissor Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-lower-tension"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={tension1_781}
            title="Tension Set (Lower)"
            price={55}
            del_price={80}
            off={39}
            alt="tension set"
            img_title="Tension Set"
          />
        </Link>
      </div>
    </>
  );
};

export default ProductsPage;
