import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import needle_clamp from "../../../../images/m700-spares/m700-needle-clamp.jpg";

const NeedleClamp = () => {
  return (
    <div>
      <ProductPage
        image={needle_clamp}
        alt="m700 needle clamp"
        img_title="M700 Needle Clamp"
        title="Needle Clamp for M-700 Overlock"
        price={70}
        del_price={110}
        discount={32}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Needle Clamp"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's M700 4 thread Overlock Machine"
        Highlight_4="Imported Product"
        key_features="Needle Clamp for M700 Model 4 Thread Overlock Sewing Machine."
      />
      <SpecsSpares
        brand_name="Generic"
        type="Needle Clamp"
        compatibility="Pegasus M700 Overlock Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  );
};

export default NeedleClamp;
