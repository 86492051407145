import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import needle_plate from "../../../../images/m700-spares/m700-needle-plate.jpg";

const NeedlePlate = () => {
  return (
    <div>
      <ProductPage
        image={needle_plate}
        alt="m700 Needle Plate"
        img_title="M700 Needle Plate"
        title="Needle Plate for M-700 Overlock"
        price={70}
        del_price={110}
        discount={28}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Needle Plate"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's M700 4 thread Overlock Machine"
        Highlight_4="Imported Product"
        key_features="Needle Plate (Light Duty) for M700 Model 4 Thread Overlock Sewing Machine."
      />
      <SpecsSpares
        brand_name="Generic"
        type="Needle Plate"
        compatibility="Pegasus M700 Overlock Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  );
};

export default NeedlePlate;
