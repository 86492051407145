import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import thread_guide_pin from "../../../../images/spare parts/single-needle/head-killi.jpg";

const ThreadGuidePinSN = () => {
  return (
    <div>
      <ProductPage
        image={thread_guide_pin}
        alt="sewing machine tension"
        title="Thread Guide Pin for Lockstitch Machine"
        price={5}
        del_price={10}
        discount={50}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Thread Guide Pin"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Thread Guide Pin for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Guide Pin"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  )
}

export default ThreadGuidePinSN