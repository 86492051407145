import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import pump from "../../../../images/spare parts/single-needle/pump-new.jpg";

const PumpSN = () => {
  return (
    <div>
      <ProductPage
        image={pump}
        alt="sewing machine oil pump"
        title="Oil Pump for Lockstitch Machine"
        price={90}
        del_price={130}
        discount={28}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Oil Pump"
        Highlight_1="High Quality Material"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Oil Pump for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Pump"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  )
}

export default PumpSN