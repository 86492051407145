import React from "react";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import silti_pro from "../../../images/steam-press/silti-pro.jpg"

const SiltiPro = () => {
  return (
    <div>
      <ProductPage
        image={silti_pro}
        alt="Silti Pro ST-96 Steam Press Set"
        img_title="Silti Pro ST-96 Steam Press"
        title="Silti Pro ST-96 Steam Press"
        price={2699}
        del_price={4000}
        discount={32}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Steam Iron, Bottle (4L), Bottle, Pen, Mat, Pipe"
        Highlight_1="Power Saves upto 75%"
        Highlight_2="Aluminium Body"
        Highlight_3="Heavy Duty"
        Highlight_4="1600 Watt"
        key_features="Silti Pro ST-96 1600 watt steam iron is a right choice of laundry person . those whoe want start laundry business . we adb sewing machine and technical services recommend big size 1600 watt iron becouse we are in this profession since 1992 . we know better than others . We know well what is better for a professional laundry man . Hope when big size 1600 watt hard anodized silver base with multy out put holes release a busty steam . hope you must be feel amazed ."
      />
      <Specs brand_name="Silti"
      model_name="Pro ST-96"
      color="Black & Silver"
      type="Power Saving"
      material="Heavy Duty"
      speed="1600 Watt"
      shape="Round"
       />
    </div>
  );
};

export default SiltiPro;
