import React from "react";
import { Helmet } from "react-helmet";
const SEO = ({ title, description, name, type, url, main_image, keywords }) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>{title}</title>
      <meta name="HandheldFriendly" content="True" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:site_name" content="Sewix.in" />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={main_image} />

      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={main_image} />
      {/* End Twitter tags */}
    </Helmet>
  );
};
export default SEO;
