import React from "react";
import ProductPage from "../../../components/ProductPage";
import SEO from "../../../components/SEO";
import Specs from "../../../components/Specs";

import esda from "../../../images/esda/esda-new-1.jpg";

const EsdaPowerSavingMotor = () => {
  return (
    <div>
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
      <SEO
        title="ESDA Power Saving Servo Motor"
        description="Upgrade your industrial equipment with the ESDA Power Saving Servo Motor. Achieve 30% energy savings, smooth control and durable design suitable for harsh environments."
        keywords="esda servo motor,power saving servo motor	,energy efficient servo motor	, servo motor energy savings	, esda servo motor technology	, esda servo motor applications	,servo motor energy consumption	,esda servo motor error codes, sewing machine motor, sewix"
        name="Sewix"
        type="website"
        url="https://www.sewix.in/esda-power-saving-motor"
        main_image={esda}
      />
      <ProductPage
        image={esda}
        alt="esda servo motor"
        img_title="Esda Servo Motor"
        title="Esda Power Saving Servo Motor"
        price={2999}
        del_price={4500}
        discount={32}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Genuine Product"
        box_content="Motor, Board, Fittings, Connecting Rod, User Manual"
        Highlight_1="High Efficiency Servo Motor"
        Highlight_2="Achieve Maximum Energy Savings"
        Highlight_3="Smooth and Precise Control"
        Highlight_4="Durable and Reliable Servo Motor"
        key_features="

        Achieve maximum energy savings with the ESDA power saving servo motor. Utilizing advanced technology, this servo motor is designed to reduce power consumption by up to 30% compared to traditional motors.
        Smooth and Precise Control
        Enjoy smooth and precise control with the ESDA power saving servo motor. With a high resolution encoder and advanced control algorithm, this servo motor provides accurate and stable performance for a wide range of applications.
        Durable and Reliable
        Built to last, the ESDA power saving servo motor is made with high-quality materials and is designed for long-term use. With a compact and durable design, it is perfect for harsh industrial environments."
      />
      <Specs
        brand_name="Esda"
        model_name="Servo"
        color="Blue & Silver"
        type="Power Saving"
        material="Aluminium"
        speed="5000"
        shape="Round"
      />
    </div>
  );
};

export default EsdaPowerSavingMotor;
