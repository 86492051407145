import React from "react";
import SEO from "../../components/SEO";
import Product from "../../components/Product";
import "../../styles/ServoMotor.css";
import { Link } from "react-router-dom";

// images
import esda_steam from "../../images/steam-press/esda-steam.jpg";
import silti_pro from "../../images/steam-press/silti-pro.jpg";
import full_hole from "../../images/steam-press/full-hole-steam.jpg";
import iron from "../../images/steam-press/industrial-iron.jpg";
import silti_96 from "../../images/steam-press/silit-96.jpg";
import silti_300lb from "../../images/steam-press/silti-300lb.jpg";
import silver1 from "../../images/steam-press/silver-star-2050.jpg";
import silver2 from "../../images/steam-press/silver-star-2999.jpg";
import yoke from "../../images/steam-press/yoke-full.jpg";




const SteamPress = () => {
  return (
    <div className="servo-motor">
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
<SEO
title="Steam Press - Professional Results at Home - Adjustable Temperature and Pressure"
description="The Steam Press is the ultimate solution for achieving professional results at home. With its powerful steam function, adjustable temperature and pressure control, and large pressing surface, this press makes it easy to remove wrinkles and create crisp, professional-looking fabrics. Its convenient and easy to use, automatic shut-off feature makes it perfect for everyday use and its compact design makes it easy to store. Order now and take your pressing to the next level."
name="Sewix"
type="website"
url="https://www.sewix.in/steam-press-iron"
main_image={silti_300lb}
keywords="silti steam press, silver star, shilter, Clothes steam press, Garment steam press, Fabric steam press, Steam iron press, Steam press machine, Steam press for clothes, Steam press for laundry, Steam press for home use, Steam press for commercial use, Steam press for quilting, Steam press for embroidery, Steam press for curtains, Steam press for linens, Steam press for shirts, Steam press for pants, Steam press for dresses, Steam press for tablecloths, Steam press for napkins, Steam press for bed sheets, Steam press for duvets, Steam press for curtains, Steam press for silk, Steam press for wool, Steam press for cotton" />
      <Link
        to="/esda-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={esda_steam}
          title="Esda Steam Press Set"
          price={2299}
          del_price={3500}
          off={32}
          alt="Steam Press Iron"
          img_title="Esda Steam Press Iron"
        />
      </Link>
      <Link
        to="/silti-st96-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={silti_96}
          title="Silti ST-96 Steam Press Set"
          price={2499}
          del_price={3500}
          off={32}
          alt="Steam Press Iron"
          img_title="Silti ST-96 Steam Press Iron"
        />
      </Link>
      <Link
        to="/silti-pro-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={silti_pro}
          title="Silti Pro ST-96 Steam Press Set"
          price={2699}
          del_price={4000}
          off={32}
          alt="Steam Press Iron"
          img_title="Silti Pro Steam Press Iron"
        />
      </Link>
      <Link
        to="/silti-es300lb-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={silti_300lb}
          title="Silti ES-300L-B Steam Press Set"
          price={2799}
          del_price={4500}
          off={32}
          alt="Steam Press Iron"
          img_title="Silti ES-300L-B Steam Press Iron"
        />
      </Link>
      <Link
        to="/silver-star-1-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={silver1}
          title="Silver Star Steam Press"
          price={2599}
          del_price={4500}
          off={32}
          alt="Steam Press Iron"
          img_title="Silver Star Steam Press Iron"
        />
      </Link>
      <Link
        to="/silver-star-2-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={silver2}
          title="Silver Star Steam Press Set"
          price={2999}
          del_price={5000}
          off={32}
          alt="Steam Press Iron"
          img_title="Silver Star Steam Press Iron"
        />
      </Link>
      <Link
        to="/weijie-full-hole-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={full_hole}
          title="Weijie 999 Full Hole Steam Press"
          price={2799}
          del_price={4500}
          off={32}
          alt="Steam Press Iron"
          img_title="full hole Steam Press Iron"
        />
      </Link>
      <Link
        to="/yoke-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={yoke}
          title="Yoke (Full Body Hole) Steam Press Set"
          price={2499}
          del_price={3500}
          off={32}
          alt="Steam Press Iron"
          img_title="Yoke Steam Press Iron"
        />
      </Link>
      <Link
        to="/industrial-iron-steam-press-iron"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={iron}
          title="Industrial Iron (5kg) Steam Press Set"
          price={2299}
          del_price={3500}
          off={32}
          alt="Steam Press Iron"
          img_title="Industrial Iron Steam Press Iron"
        />
      </Link>
    </div>
  );
};

export default SteamPress;
