import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import pressure_cap from "../../../../images/spare parts/single-needle/pressure-cap.jpg";

const PressureCapSN = () => {
  return (
    <div>
      <ProductPage
        image={pressure_cap}
        alt="pressure cap"
        title="Pressure Cap for Lockstitch Machine"
        price={4}
        del_price={10}
        discount={60}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Pressure Cap"
        Highlight_1="High Quality Material"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Pressure Cap for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Pressure Cap"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Material"
      />
    </div>
  )
}

export default PressureCapSN