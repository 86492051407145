import React from "react";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";
import { Link } from "react-router-dom";

// IMAGES
import tension from "../../images/spare parts/single-needle/tension-juki.jpg";
import tension1_781 from '../../images/spare parts/juki-781/tension1.jpg'
import tension2_781 from '../../images/spare parts/juki-781/tension2.jpg'

const TensionsCat = () => {
  return (
    <div className="spare-parts-categories">
      <Link to="/tension" style={{ textDecoration: "none", color: "black" }}>
        <Product
          image={tension}
          title="Tension Set for Lockstich"
          price={20}
          del_price={45}
          off={32}
        />
      </Link>
      <Link
          to="/juki-butttonhole-lower-tension"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={tension1_781}
            title="Tension Set (Lower)"
            price={55}
            del_price={80}
            off={39}
            alt="tension set"
            img_title="Tension Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-upper-tension"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={tension2_781}
            title="Tension Set (upper)"
            price={55}
            del_price={80}
            off={39}
            alt="tension set"
            img_title="Tension Set"
          />
        </Link>
    </div>
  );
};

export default TensionsCat;
