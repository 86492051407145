import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import thread_guide from "../../../../images/spare parts/single-needle/2hole-guide.jpg";

const ThreadGuide2HoleSN = () => {
  return (
    <div>
      <ProductPage
        image={thread_guide}
        alt="sewing machine thread guide"
        title="2 Hole Thread Guide for Lockstitch Machine"
        price={25}
        del_price={45}
        discount={43}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Thread Guide"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Thread Guide for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Guide"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  )
}

export default ThreadGuide2HoleSN