import React from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";

const Header = () => {
  return (
    <header className="header">
      <Link
        to="/"
        style={{ textDecoration: "none", color: "black" }}
      >
        <h1 className="logo">Sewix</h1>
      </Link>
      <a className="number" href="tel: +91 9354237363">
        +91 9354237363
      </a>
    </header>
  );
};

export default Header;
