import React from "react";
import "../styles/Page404.css"
import { Link } from "react-router-dom";
import ProductCategory from "../components/ProductCategory";

// Images
import u1_usha from "../images/Usha-hand/usha1.png";
import servo from "../images/servo.jpg";
import s1_spare from "../images/shuttle.jpeg";
import press from "../images/silti-press.jpg";

import shuttle from "../images/spare parts/hook-sets.jpg"
import boot from "../images/spare parts/pressure-foot.jpg"
import tension from "../images/spare parts/tension.jpg"
import needle_plate from "../images/spare parts/needle-plates.jpg"
import feed_dog from "../images/spare parts/feed-dog.jpg"
import bobbin_case from "../images/spare parts/bobbin-case.jpg"
import ViewAll from "../components/ViewAll";

const Page404 = () => {
  return (
    <>
      <h2 className="page404">Page Not Found! Don't Worry</h2>
      <ViewAll name="Category" link="/" />
      <div className="products">
        <Link
          to="domestic-sewing-machine"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory image={u1_usha} title="Sewing Machines" />
        </Link>
        <Link
          to="sewing-machine-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory image={servo} title="Sewing Machine Motors" />
        </Link>
        <Link
          to="sewing-machine-parts"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory image={s1_spare} title="Sewing Machine Parts" />
        </Link>
        <Link
          to="sewing-machine-accessories"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ProductCategory image={press} title="Sewing Machine Accessories" />
        </Link>
      </div>
      <ViewAll name="Sewing Parts" link="sewing-machine-parts" />
      <div className="products spare-list">
        <Link className="spare"
          to="esda-power-saving-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img className="spare-img" src={shuttle} alt="" />
        </Link>
        <Link className="spare"
          to="vicco-priya-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img className="spare-img" src={boot} alt="" />
        </Link>
        <Link className="spare"
          to="hmc-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img className="spare-img" src={tension} alt="" />
        </Link>
        <Link className="spare"
          to="breostar-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img className="spare-img" src={needle_plate} alt="" />
        </Link>
        <Link className="spare"
          to="breostar-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img className="spare-img" src={feed_dog} alt="" />
        </Link>
        <Link className="spare"
          to="breostar-servo-motor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img className="spare-img" src={bobbin_case} alt="" />
        </Link>
        </div>
    </>
  );
};

export default Page404;
