import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import Spring_rod from "../../../../images/spare parts/single-needle/spring-rod.jpg";

const SpringRodSN = () => {
  return (
    <div>
    <ProductPage
      image={Spring_rod}
      alt="sewing machine spring rod"
      title="Pressure Foot Spring and Rod for Lockstitch Machine"
      price={9}
      del_price={15}
      discount={35}
      ruppee_icon="Cash On Delivery"
      delivery_icon="Fast delivery"
      trust_icon="Trusted Product"
      box_content="Spring Rod Set"
      Highlight_1="High Quality Steel"
      Highlight_2="Great for Industrial Use"
      Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
      Highlight_4="Imported Product"
      key_features="Spring Rod set for Industrial Lockstitch Single Needle Sewing Machine"
    />
    <SpecsSpares
      brand_name="Generic"
      type="Spring Rod"
      compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
      material="High Quality Steel"
    />
  </div>
  )
}

export default SpringRodSN