import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import pressure_foot_781 from "../../../../images/spare parts/juki-781/pressure-foot.jpg";

const PressureFoot781 = () => {
  return (
    <div>
      <ProductPage
        image={pressure_foot_781}
        alt="juki buttonhole Pressure Foot"
        title="Juki Buttonhole Pressure Foot"
        price={270}
        del_price={350}
        discount={28}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Pressure Foot"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit with Juki LBH-781, LBH-780, LBH-772"
        Highlight_4="Imported Product"
        key_features="Pressure Foot For Juki ButtonHole Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Pressure Foot"
        compatibility="Fit with Juki LBH-781, LBH-780, LBH-772"
        material="High Quality Steel"
      />
    </div>
  )
}

export default PressureFoot781