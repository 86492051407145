import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";

import gaugeset from "../../../../images/m700-spares/m700-gauge-set.jpg";

const GaugeSet = () => {
  return (
    <div>
      <ProductPage
        image={gaugeset}
        alt="m700 gauge set"
        img_title="Overlock Gauge Set"
        title="Gauge Set fro M-700 Overlock (Light Duty)"
        price={450}
        del_price={700}
        discount={39}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Complete Gauge Set - Pressure Foot, Needle Plate, Needle Clamp, Feed Dogs (Both)"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's M700 4 thread Overlock Machine"
        Highlight_4="Imported Product"
        key_features="Complete Gauge Set for M700 Model 4 Thread Overlock Sewing Machine."
      />
      <SpecsSpares
        brand_name="Generic"
        type="Gauge Set"
        compatibility="Pegasus M700 Overlock Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  );
};

export default GaugeSet;
