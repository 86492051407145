import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import juki_shuttle from "../../../../images/shuttle.jpeg";

const YokeShuttle = () => {
  return (
    <div>
      <ProductPage
        image={juki_shuttle}
        alt="sewing machine shuttle hook set"
        img_title="Yoke Shuttle"
        title="Yoke Hook Set / Shuttle for Lockstitch Machine"
        price={199}
        del_price={300}
        discount={38}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Hook Set"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Hook Set / Shuttle for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
      brand_name="Yoke"
      type="Shuttle"
      compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
      material="High Quality Steel" />
    </div>
  );
};

export default YokeShuttle;
