import React from "react";
import ProductPage from "../../components/ProductPage";
import Specs from "../../components/Specs";

import usha from "../../images/Usha-hand/bandhan_1.jpg";

const UshaBandhan = () => {
  return (
    <div>
      <ProductPage
        image={usha}
        alt="usha bandhan"
        img_title="Usha Bandhan"
        title="Usha Bandhan Straight Stitch Machine without Cover and Base"
        price={3899}
        del_price={4500}
        discount={12}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="1 Year Warranty"
        box_content="Sewing Machine, Accessories Box, Warranty Card, User Manual"
        Highlight_1="Option to operate with Motor"
        Highlight_2="Auto tripping bobbin winder for uniform winding of bobbin for perfect stitch formation."
        Highlight_3="Lever type stitch regulator with forward and reverse stitching mechanism"
        Highlight_4="ISI marked"
        key_features="Another model offered in the economy range of the Usha Straight Stitch machines, the Bandhan sewing machine comes with all features necessary for sewing. Features include auto tripping, spring loaded bobbin winder for uniform bobbin winding and perfect stitch formation, a lever type stitch regulator for easy forward and reverse stitch control, and a slide plate for easy insertion of the bobbin. Country of Origin: India. Foot variant also available.
    You will also get 1 year off-site warranty from Usha.
    "
      />
      <Specs
        brand_name="Usha"
        model_name="Bandhan"
        color="Black"
        type="Manual"
        material="Iron"
        speed="Manual"
        shape="Round"
      />
    </div>
  );
};

export default UshaBandhan;
