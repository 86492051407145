import React from "react";
import { Link } from "react-router-dom";
import "../styles/ViewAll.css";

const ViewAll = ({ name,link }) => {
  return (
    <div className="view-all">
      <h4 className="name">{name}</h4>
      <Link to={link}>
        <h4 className="view">View All</h4>
      </Link>
    </div>
  );
};

export default ViewAll;
