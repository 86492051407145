import React from "react";
import "../styles/ProductCategory.css";

const ProductCategory = ({image, title, alt, img_title}) => {
  return (
    <div className="products-category">
      <img className="img" src={image} alt={alt} title={img_title} />
      <h1 className="title">{title}</h1>
    </div>
  );
};

export default ProductCategory;
