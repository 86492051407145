import React from "react";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";
import { Link } from "react-router-dom";

// IMAGES
import boot from "../../images/boot/boot.jpg";
import m700_pressure_foot from "../../images/m700-spares/m700-pressure-foot.jpg";
import pressure_foot_781 from '../../images/spare parts/juki-781/pressure-foot.jpg'


const PressureFootsCat = () => {
  return (
    <>
      <div className="spare-parts-categories">
        <Link
          to="/pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={boot}
            alt="Pressure Foot"
            img_title="Pressure Foot for Lockstitch"
            title="Pressure Foot for Lockstitch"
            price={14}
            del_price={35}
            off={35}
          />
        </Link>
        <Link
          to="/m700-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_pressure_foot}
            alt="m700 pressure foot"
            img_title="M-700 Pressure Foot"
            title="M-700 Pressure Foot"
            price={60}
            del_price={85}
            off={21}
          />
        </Link>
        <Link
          to="/juki-butttonhole-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_781}
            title="Pressure Foot"
            price={270}
            del_price={350}
            off={28}
            alt="pressure foot"
            img_title="Pressure Foot"
          />
        </Link>
      </div>
    </>
  );
};

export default PressureFootsCat;
