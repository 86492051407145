import React from "react";
import "../styles/Specs.css";

const Specs = ({brand_name, model_name, color, type, material, speed, shape}) => {
  return (
    <div className="specs">
      <h4>Specification:</h4>
      <table className="specs-table">
        <tr className="specs-flex">
          <td className="product-td">Brand:</td>
          <td>{brand_name}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Model name:</td>
          <td>{model_name}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Colour:</td>
          <td>{color}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Type:</td>
          <td>{type}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Material:</td>
          <td>{material}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Speed:</td>
          <td>{speed}</td>
        </tr>
        <tr className="specs-flex">
          <td className="product-td">Shape:</td>
          <td>{shape}</td>
        </tr>
      </table>
    </div>
  );
};

export default Specs;
