import React from "react";
import { Link } from "react-router-dom";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";

// images
import looper1 from "../../images/m700-spares/204072-looper.jpg";
import looper2 from "../../images/m700-spares/204702-looper.jpg";

const LoopersCat = () => {
  return (
    <>
      <h4 className="sub-title">M700 Overlock Parts</h4>
      <div className="spare-parts-categories">
        <Link
          to="/m700-looper-204072"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper1}
            title="M-700 Looper 204072"
            price={45}
            del_price={70}
            off={35}
            alt="204072 looper"
            img_title="M-700 Looper"
          />
        </Link>
        <Link
          to="/m700-looper-204702"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={looper2}
            title="M-700 Looper 204702"
            price={45}
            del_price={70}
            off={35}
            alt="204702 looper"
            img_title="M-700 Looper"
          />
        </Link>
      </div>
    </>
  );
};

export default LoopersCat;
