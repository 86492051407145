import React from "react";
import SEO from "../../components/SEO";
import Product from "../../components/Product";
import "../../styles/ServoMotor.css";
import { Link } from "react-router-dom";


// images
import esda_pcb from "../../images/pcb/esda-pcb.jpg";
import jack_804d_pcb from "../../images/pcb/jack-804d-pcb.jpg";
import jack_a2_pcb from "../../images/pcb/jack-a2-pcb.jpg";
import jack_a2s_pcb from "../../images/pcb/jack-a2s-pcb.jpg";
import jack_f4_pcb from "../../images/pcb/jack-f4-pcb.jpg";
import jack_9100bp from "../../images/pcb/jack-9100bp.jpg";
import jack_9100ba from "../../images/pcb/jack-9100ba.jpg";

// import Jack804DPcb from "../Product_Pages/PCB/Jack804DPcb";


const ServoMotor = () => {
  return (
    <div className="servo-motor">
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
     <SEO
title="PCB Board - The Heart of Your Circuit - Versatile Applications"
description="The PCB Board is an essential component for any electronic circuit. With its versatile applications, durable and reliable performance, it serves as the foundation for all the electrical components, providing a stable and reliable platform for your circuit to operate on. Made of high-quality materials and precision manufacturing, it's sure to meet all your circuit needs."
name="Sewix"
type="website"
url="https://www.sewix.in/pcb-board"
main_image={jack_f4_pcb}
keywords="jack f4, jack a2, esda servo motor, Sewing machine control board, PCB board for sewing machine, Sewing machine circuit board, Main PCB for sewing machine, Sewing machine motherboard, Sewing machine power board, Sewing machine drive board, Sewing machine motor control board, Sewing machine interface board, Sewing machine display board, Sewing machine memory board, Sewing machine microcontroller board, Sewing machine logic board, Sewing machine power supply board, Sewing machine sensor board, Sewing machine communication board, PCB board repair for sewing machine, Sewing machine PCB assembly, Sewing machine PCB design, PCB board replacement for sewing machine, Sewing machine PCB troubleshooting, Sewing machine PCB upgrade, PCB board for industrial sewing machine." />
        <Link
        to="/jack-f4-pcb"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={jack_f4_pcb}
          title="Jack F4 PCB"
          price={2299}
          del_price={5500}
          off={60}
          alt="Jack F4 PCB"
            img_title="Jack F4 PCB"
        />
      </Link>
      <Link
        to="/esda-servo-motor-pcb"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={esda_pcb}
          title="Esda Servo Motor PCB"
          price={1599}
          del_price={2399}
          off={32}
          alt="Esda Servo Motor PCB"
            img_title="Esda Servo Motor PCB"
        />
      </Link>
      <Link
        to="/jack-9100bp-pcb"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={jack_9100bp}
          title="Jack 9100b (New Model) PCB"
          price={3499}
          del_price={6000}
          off={32}
          alt="Jack 9100b PCB"
            img_title="Jack 9100b PCB"
        />
      </Link>
      <Link
        to="/jack-9100ba-pcb"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={jack_9100ba}
          title="Jack 9100 (Old Model) PCB"
          price={3899}
          del_price={8000}
          off={42}
          alt="Jack 9100b PCB"
            img_title="Jack 9100b PCB"
        />
      </Link>
      <Link
        to="/jack-a2-pcb"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={jack_a2_pcb}
          title="Jack A2 PCB"
          price={3299}
          del_price={6000}
          off={45}
          alt="Jack A2 PCB"
            img_title="Jack A2 PCB"
        />
      </Link>
      <Link
          to="/jack-a2s-pcb"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={jack_a2s_pcb}
            title="Jack A2s PCb"
            price={4299}
            del_price={6000}
            off={32}
            alt="Jack A2s PCB Board"
            img_title="Jack A2s PCB Board"
          />
        </Link>
      <Link
        to="/jack-804d-pcb"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={jack_804d_pcb}
          title="Jack 804D PCB"
          price={2999}
          del_price={4500}
          off={32}
          alt="Jack 804D PCB"
            img_title="Jack 804D PCB"
        />
      </Link>
    </div>
  );
};

export default ServoMotor;
