import React from "react";
import SEO from "../../components/SEO";
import Product from "../../components/Product";
import "../../styles/ServoMotor.css";
import { Link } from "react-router-dom";

// images
import breostar_110 from "../../images/cutting-machine/breostar-110.jpg";
import breostar_125 from "../../images/cutting-machine/breostar-125.jpg";
import taking_65 from "../../images/cutting-machine/taking-65.jpg";
import lipu_8 from "../../images/cutting-machine/lipu-8.jpg";
import breostar_8 from "../../images/cutting-machine/breostar-8.jpg";
import cupid_110 from "../../images/cutting-machine/cupid-110.jpg";
import cupid_125 from "../../images/cutting-machine/cupid-125.jpg";
import taking from "../../images/cutting-machine/taking.jpg";
import dtech from "../../images/cutting-machine/dtech.jpg";






const CuttingMachine = () => {
  return (
    <div className="servo-motor">
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
      <SEO 
      title="Cloth Cutting Machine"
      description=" Effortlessly cut through any fabric with precision using our powerful cloth cutting machine. Designed for both professionals and DIY enthusiasts, this versatile cutting solution is perfect for all your fabric cutting needs. Achieve professional results every time with our intuitive controls and durable design."
      name="Sewix"
      type="website"
      url="https://www.sewix.in/cloth-cutting-machine"
      main_image={taking} 
      keywords= "Fabric cutting machine, Laser cutting machine, CNC cutting machine, Automatic cutting machine, Industrial cutting machine, Cloth cutter, Textile cutting machine, Garment cutting machine, Pattern cutting machine, Roll cutting machine, Computerized cutting machine, Precision cutting machine, Ultrasonic cutting machine, Rotary cutting machine, Flatbed cutting machine, Manual cutting machine, Fabric cutter, Textile cutter, Garment cutter, Pattern cutter, Roll cutter, Computerized cutter, Precision cutter."/>
      <Link
        to="/breostar-110mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={breostar_110}
          title="Breostar 110mm Cutting Machine"
          price={2599}
          del_price={5000}
          off={32}
          alt="Breostar 110mm Cutting Machine"
          img_title="Cloth Cutting Machine"
        />
      </Link>
      <Link
        to="/breostar-125mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={breostar_125}
          title="Breostar 125mm Cutting Machine"
          price={2899}
          del_price={6000}
          off={32}
          alt="cloth cutting machine"
          img_title="Breostar 125mm Cutting Machine"
        />
      </Link>
      <Link
        to="/breostar-125mm-heavy-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={breostar_110}
          title="Breostar 125mm Cutting Machine (Heavy)"
          price={3099}
          del_price={6500}
          off={32}
          alt="cloth cutting machine"
          img_title="Breostar 125mm Cutting Machine"
        />
      </Link>
      <Link
        to="/taking-65mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={taking_65}
          title="Taking 65mm Cutting Machine"
          price={3299}
          del_price={4500}
          off={32}
          alt="cloth cutting machine"
          img_title="Taking 65mm Cutting Machine"
        />
      </Link>
      <Link
        to="/cupid-110mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={cupid_110}
          title="Cupid 110mm Cutting Machine"
          price={4899}
          del_price={6000}
          off={32}
          alt="cloth cutting machine"
          img_title="Cupid 110mm Cutting Machine"
        />
      </Link>
      <Link
        to="/cupid-125mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={cupid_125}
          title="Cupid 125mm Cutting Machine"
          price={5299}
          del_price={7000}
          off={32}
          alt="cloth cutting machine"
          img_title="Cupid 125mm Cutting Machine"
        />
      </Link>
      <Link
        to="/dtech-110mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={dtech}
          title="Dtech 110mm Cutting Machine"
          price={4999}
          del_price={7000}
          off={32}
          alt="cloth cutting machine"
          img_title="Dtech 110mm Cutting Machine"
        />
      </Link>
      <Link
        to="/dtech-125mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={dtech}
          title="Dtech 125mm Cutting Machine"
          price={5499}
          del_price={8000}
          off={32}
          alt="cloth cutting machine"
          img_title="Dtech 125mm Cutting Machine"
        />
      </Link>
      <Link
        to="/taking-110mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={taking}
          title="Taking 110mm Cutting Machine"
          price={5399}
          del_price={6500}
          off={32}
          alt="cloth cutting machine"
          img_title="Taking 110mm Cutting Machine"
        />
      </Link>
      <Link
        to="/taking-125mm-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={taking}
          title="Taking 125mm Cutting Machine"
          price={6299}
          del_price={9000}
          off={32}
          alt="cloth cutting machine"
          img_title="Taking 125mm Cutting Machine"
        />
      </Link>
      <Link
        to="/lippu-8inch-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={lipu_8}
          title="Lippu 8 inch Cutting Machine"
          price={13999}
          del_price={20000}
          off={32}
          alt="cloth cutting machine"
          img_title="Lippu 8 inch Cutting Machine"
        />
      </Link>
      <Link
        to="/breostar-8inch-cloth-cutting-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={breostar_8}
          title="Breostar 8 inch Cutting Machine (Copper)"
          price={14999}
          del_price={25000}
          off={32}
          alt="cloth cutting machine"
          img_title="Breostar 8 ihch Cutting Machine"
        />
      </Link>
    </div>
  );
};

export default CuttingMachine;
