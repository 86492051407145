import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import tank_killi from "../../../../images/spare parts/single-needle/tank-killi.jpg";

const TankKilliSN = () => {
  return (
    <div>
      <ProductPage
        image={tank_killi}
        alt="sewing machine tank killi"
        title="Tank Killi for Lockstitch Machine"
        price={4}
        del_price={10}
        discount={60}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Tension Set"
        Highlight_1="High Quality Material"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Tank Killi for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Tank Killi"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality MAterial"
      />
    </div>
  )
}

export default TankKilliSN