import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";

import flying_tiger from "../../../../images/spare parts/single-needle/flying-tiger-db1.jpg"

const FlyingTigerNeedleDB1 = () => {
  return (
    <div>
      <ProductPage
        image={flying_tiger}
        alt="flying tiger needle"
        img_title="Flying Tiger DBx1 Needle"
        title="Flying Tiger DBx1 Needle"
        price={15}
        del_price={30}
        discount={50}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Original Product"
        box_content="Needle Packet"
        Highlight_1="Original Product"
        Highlight_2="High Quality Material"
        Highlight_3="DBx1 size - 14"
        Highlight_4="Industrial Use"
        key_features="Flying Tiger Needle For Industial Sewing Machine. Size DBx1 no. 14 ideal for Lockstitch Sewing machine"
      />
      <SpecsSpares
        brand_name="Flying Tiger"
        compatibility="DBx1"
        type="Needle"
        material="Steel"
        />
    </div>
  );
};

export default FlyingTigerNeedleDB1;
