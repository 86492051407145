import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import gauge_set from "../../../../images/spare parts/single-needle/gauge-set.jpg";

const GaugeSet = () => {
  return (
    <div>
      <ProductPage
        image={gauge_set}
        alt="gauge set"
        img_title="Guage Set"
        title="Gauge Set Complete for Lockstitch Machine"
        price={60}
        del_price={105}
        discount={38}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Gauge Set"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Gauge Set for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
      brand_name="Generic"
      type="Gauge Set"
      compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
      material="High Quality Steel" />
    </div>
  );
};

export default GaugeSet;
