import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import pulley from "../../../../images/spare parts/single-needle/pulley.jpg";

const PulleySN = () => {
  return (
    <div>
      <ProductPage
        image={pulley}
        alt="sewing machine tension"
        title="Pulley Set for Lockstitch Machine"
        price={90}
        del_price={45}
        discount={43}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Pulley"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Pulley for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Pulley"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  )
}

export default PulleySN