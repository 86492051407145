import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import lifter from "../../../../images/spare parts/single-needle/big-lifter.jpg";


const BigLifterSN = () => {
  return (
    <div>
      <ProductPage
        image={lifter}
        alt="sewing machine lifter"
        title="Lifter for Lockstitch Machine"
        price={25}
        del_price={45}
        discount={43}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Lifter"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Lifter for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Tension"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  )
}

export default BigLifterSN