import React from "react";
import "../styles/Navbar_mobile.css";
import HomeIcon from "@material-ui/icons/Home";
import CategoryIcon from "@material-ui/icons/Category";
import CallIcon from "@material-ui/icons/Call";
import { Link } from "react-router-dom";

const Navbar_mobile = () => {
  return (
    <nav className="navbar">
      <Link to="/">
        <h4>
          <HomeIcon fontSize="small" /> Home
        </h4>
      </Link>
      <Link to="/products">
        <h4>
          <CategoryIcon fontSize="small" /> Products
        </h4>
      </Link>
      <Link to="contact">
        <h4 className="navbar-last">
          <CallIcon fontSize="small" /> Contact
        </h4>
      </Link>
    </nav>
  );
};

export default Navbar_mobile;
