import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import bobbin_case from "../../../../images/spare parts/juki-781/bobbin-case.jpg";

const BobbinCase781 = () => {
  return (
    <div>
    <ProductPage
      image={bobbin_case}
      alt="juki buttonhole tension"
      title="Haya Juki Buttonhole Bobbin Case"
      price={350}
      del_price={400}
      discount={23}
      ruppee_icon="Cash On Delivery"
      delivery_icon="Fast delivery"
      trust_icon="Trusted Product"
      box_content="Bobbin Case"
      Highlight_1="High Quality Steel"
      Highlight_2="Great for Industrial Use"
      Highlight_3="Fit with Juki LBH-781, LBH-780, LBH-772"
      Highlight_4="Imported Product"
      key_features="Bobbin Case For Juki ButtonHole Sewing Machine"
    />
    <SpecsSpares
      brand_name="Haya"
      type="Bobbin Case"
      compatibility="Fit with Juki LBH-781, LBH-780, LBH-772"
      material="High Quality Steel"
    />
  </div>
  )
}

export default BobbinCase781