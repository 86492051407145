import React from "react";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import pcb from "../../../images/pcb/esda-pcb.jpg";

const JackF4Pcb = () => {
  return (
    <div>
      <ProductPage
        image={pcb}
        alt="esda servo motor pcb board"
        img_title="ESDA Servo Motor PCB"
        title="Esda PCB Board"
        price={1599}
        del_price={1899}
        discount={28}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Original Product"
        box_content="PCB Board"
        Highlight_1="Brand new PCB Board"
        Highlight_2="Fully Running PCB with all specs"
        Highlight_3="Imported PCB Board"
        Highlight_4="Esda Board"
        key_features="Esda Power Saving Servo Motor PCB Board.
    "
      />
      <Specs
        brand_name="ESDA"
        model_name="YD-255"
        color="Green"
        type="PCB Board"
        material="-"
        speed="-"
        shape="-"
      />
    </div>
  );
};

export default JackF4Pcb;
