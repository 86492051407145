import React from "react";
import "../styles/ContactPage.css";

const ContactPage = () => {
  return (
    <div>
      <h3 className="contact">Contact</h3>
      <h3>Call: +91 9354237363</h3>
      <h3>Email: Sewixofficial@gmail.com</h3>
      <h3>Whatsapp: +91 9354237263</h3>
      <hr />
    </div>
  );
};

export default ContactPage;
