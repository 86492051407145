import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import bush from "../../../../images/spare parts/single-needle/bush.jpg";

const NeedleBarBush = () => {
  return (
    <div>
      <ProductPage
        image={bush}
        alt="Needle Bar Bush"
        img_title="Needle Bar Bush"
        title="Needle Bar Bush for Lockstitch Sewing Machine"
        price={22}
        del_price={50}
        discount={60}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Needle Bar Bush"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Needle Bar Bush for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Bush"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Steel"
      />
    </div>
  );
};

export default NeedleBarBush;
