import React from "react";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";
import { Link } from "react-router-dom";

// IMAGES
import needle_plate from "../../images/spare parts/single-needle/juki-plate.jpg";
import m700_needle_plate from "../../images/m700-spares/m700-needle-plate.jpg";

const NeedlePlatesCat = () => {
  return (
    <div>
      <div className="spare-parts-categories">
        <Link
          to="/yoke-hook-set"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={needle_plate}
            alt="needle plate"
            img_title="Needle Plate for Lockstitch"
            title="Needle Plate for Lockstitch"
            price={17}
            del_price={35}
            off={45}
          />
        </Link>
        <Link
          to="/m700-needle-plate"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={m700_needle_plate}
            title="M-700 Needle Plate"
            price={70}
            del_price={110}
            off={28}
            alt="m700 needle plate"
            img_title="M-700 Needle Plate"
          />
        </Link>
      </div>
    </div>
  );
};

export default NeedlePlatesCat;
