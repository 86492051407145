import React from "react";
import { Link } from "react-router-dom";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";

// Images
import shuttle781 from "../../images/spare parts/juki-781/shuttle.jpg";
import bobbin_case_781 from "../../images/spare parts/juki-781/bobbin-case.jpg";
import bobbin781 from "../../images/spare parts/juki-781/bobbin.jpg";
import pressure_foot_781 from "../../images/spare parts/juki-781/pressure-foot.jpg";
import scissor781 from "../../images/spare parts/juki-781/scissor.jpg";
import tension1_781 from "../../images/spare parts/juki-781/tension1.jpg";
import tension2_781 from "../../images/spare parts/juki-781/tension2.jpg";

const ButtonHoleCat = () => {
  return (
    <div>
      <h4 className="sub-title">M700 Overlock Parts</h4>
      <div className="spare-parts-categories">
        <Link
          to="/juki-butttonhole-shuttle"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={shuttle781}
            title="Rnd Original Shuttle / Hook Set"
            price={1050}
            del_price={700}
            off={39}
            alt="juki buttonhole shuttle"
            img_title="Rnd Hook Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin-case"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin_case_781}
            title="Haya Bobbin Case"
            price={320}
            del_price={400}
            off={23}
            alt="haya bobbin case"
            img_title="Haya Bobbin Case"
          />
        </Link>
        <Link
          to="/juki-butttonhole-bobbin"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin781}
            title="Bobbin"
            price={30}
            del_price={70}
            off={65}
            alt="juki bobbin"
            img_title="Bobbin"
          />
        </Link>
        <Link
          to="/juki-butttonhole-pressure-foot"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pressure_foot_781}
            title="Pressure Foot"
            price={230}
            del_price={350}
            off={28}
            alt="pressure foot"
            img_title="Pressure Foot"
          />
        </Link>
        <Link
          to="/juki-butttonhole-scissor"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={scissor781}
            title="Scissor Set"
            price={230}
            del_price={350}
            off={28}
            alt="juki buttonhole scissor"
            img_title="Scissor Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-lower-tension"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={tension1_781}
            title="Tension Set (Lower)"
            price={50}
            del_price={80}
            off={39}
            alt="tension set"
            img_title="Tension Set"
          />
        </Link>
        <Link
          to="/juki-butttonhole-upper-tension"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={tension2_781}
            title="Tension Set (Upper)"
            price={50}
            del_price={80}
            off={39}
            alt="tension set"
            img_title="Tension Set"
          />
        </Link>
      </div>
    </div>
  );
};

export default ButtonHoleCat;
