import React from "react";
import "../styles/COD.css";
import SecurityIcon from "@material-ui/icons/Security";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

const COD = ({ ruppee_icon, delivery_icon, trust_icon }) => {
  return (
    <div className="hero-icon">
        <h5>
        <SecurityIcon />
        {trust_icon}
        <br /> Genuine Products 
      </h5>
      <h5>
        <MonetizationOnRoundedIcon />
        {ruppee_icon}
        <br /> Cash On Delivery
      </h5>
      <h5>
        <LocalShippingIcon />
        {delivery_icon}
        <br /> Fast Delivery
      </h5>
    </div>
  );
};

export default COD;

