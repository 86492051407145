import React from "react";
import SEO from "../../components/SEO";
import Product from "../../components/Product";
import "../../styles/ServoMotor.css";
import { Link } from "react-router-dom";

// images
// import esda from "../../images/esda/esda-1-new.png";
import esda from "../../images/esda/esda-new-1.jpg";
import hmc_motor from "../../images/servo-motor/hmc-motor.jpg";
import jin_servo from "../../images/servo-motor/jin-servo.jpg";
import jack_servo from "../../images/servo-motor/jack-servo.jpg";

import messer from "../../images/servo-motor/messer.jpg";
import esda_dd from "../../images/servo-motor/esda-dd.jpg";
import hmc_dd from "../../images/servo-motor/hmc-dd.jpg";
import vicco_priya from "../../images/servo-motor/vicco-priya-1-new.jpg";
import hmc from "../../images/servo-motor/hmc-1-new.jpg";
import servo_1 from "../../images/servo-motor/servo-1.jpg";
import servo_2 from "../../images/servo-motor/servo-2.jpg";
import akari from "../../images/servo-motor/akari.jpg";
import overlock_servo from "../../images/servo-motor/overlock-servo.jpg";
// import breostar from "../../images/breostar-motor/breostar2.png";
// import akari from "../../images/motor/akari-servo/akari-0.jpg"

const ServoMotor = () => {
  return (
    <div className="servo-motor">
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
      <SEO
        title="Sewing Machine Motor - Unmatched Power and Speed - Durable and Long-Lasting"
        description="The Sewing Machine Motor is a powerful and efficient addition to any sewing machine. With its high torque and speed capabilities, it delivers smooth and consistent performance, whether you're working on heavy-duty fabrics or delicate fabrics. Designed to be durable and long-lasting, this motor features an advanced cooling system that keeps the motor running at optimal temperatures. Easy to install and maintain, this motor is the perfect upgrade for your sewing machine."
        name="Sewix"
        type="website"
        url="https://www.sewix.in/sewing-machine-motor"
        main_image={esda}
        keywords="Electric motor for sewing machine, Sewing machine drive motor, AC motor for sewing machine, DC motor for sewing machine, Servo motor for sewing machine, Stepper motor for sewing machine, Brushless motor for sewing machine, Induction motor for sewing machine, Gear motor for sewing machine, High-speed motor for sewing machine, Sewing machine synchronous motor, Sewing machine asynchronous motor, Sewing machine brushless DC motor, Sewing machine universal motor, Sewing machine induction motor, Sewing machine servo drive motor, Sewing machine stepper drive motor, Motor for industrial sewing machine, Sewing machine motor controller, Sewing machine motor speed control, Sewing machine motor repair, Sewing machine motor replacement, Sewing machine motor upgrade."
      />
      <Link
        to="/esda-power-saving-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={esda}
          title="Esda Servo Motor"
          price={2999}
          del_price={4500}
          off={32}
          alt="sewing machine motor"
          img_title="Esda Servo Motor"
        />
      </Link>
      <Link
        to="/hmc-servo-motor-new"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={hmc_motor}
          title="HMC Servo Motor"
          price={3099}
          del_price={4500}
          off={32}
          alt="sewing machine motor"
          img_title="Hmc Servo Motor"
        />
      </Link>
      <Link
        to="/messer-servo-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={messer}
          title="Messer Servo Motor"
          price={2799}
          del_price={4500}
          off={32}
          alt="sewing machine motor"
          img_title="Messer Servo Motor"
        />
      </Link>
      <Link
        to="/akari-servo-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={akari}
          title="Akari Servo Motor"
          price={2899}
          del_price={4000}
          off={28}
          alt="sewing machine motor"
          img_title="Akari Servo Motor"
        />
      </Link>
      <Link
        to="/jin-servo-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={jin_servo}
          title="Jin (Juki) Servo Motor"
          price={7999}
          del_price={10000}
          off={32}
          alt="sewing machine motor"
          img_title="Jin Servo Motor"
        />
      </Link>
      <Link
        to="/jack-servo-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={jack_servo}
          title="Jack Servo Motor"
          price={4299}
          del_price={7000}
          off={32}
          alt="sewing machine motor"
          img_title="Jack Servo Motor"
        />
      </Link>
      <Link
        to="/vicco-priya-servo-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={vicco_priya}
          title="Vicco Priya Servo Motor"
          price={4299}
          del_price={4500}
          off={12}
          alt="sewing machine motor"
          img_title="Vicco Priya Servo Motor"
        />
      </Link>
      <Link
        to="/hmc-servo-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={hmc}
          title="HMC Servo Motor"
          price={3999}
          del_price={4500}
          off={18}
          alt="sewing machine motor"
          img_title="Hmc Servo Motor"
        />
      </Link>
      <Link
        to="/esda-direct-drive-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={esda_dd}
          title="Esda Direct Drive Servo Motor"
          price={4299}
          del_price={6000}
          off={32}
          alt="sewing machine motor"
          img_title="Esda Direct Drive Servo Motor"
        />
      </Link>
      <Link
        to="/hmc-direct-drive-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={hmc_dd}
          title="HMC Direct Drive Servo Motor"
          price={4999}
          del_price={8000}
          off={32}
          alt="sewing machine motor"
          img_title="Hmc Direct Drive Servo Motor"
        />
      </Link>
      <Link
        to="/overlock-direct-drive-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={overlock_servo}
          title="Esda Overlock Direct Drive Motor"
          price={4299}
          del_price={6000}
          off={32}
          alt="sewing machine motor"
          img_title="Overlock Servo Motor"
        />
      </Link>
      <Link
        to="/sewing-machine-motor-1"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={servo_1}
          title="Sewing Machine Servo Motor"
          price={2999}
          del_price={4500}
          off={32}
          alt="sewing machine motor"
          img_title="Sewing Servo Motor"
        />
      </Link>
      <Link
        to="/sewing-machine-motor-2"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Product
          image={servo_2}
          title="Sewing Machine Servo Motor"
          price={3299}
          del_price={4500}
          off={32}
          alt="sewing machine motor"
          img_title="Sewing Servo Motor"
        />
      </Link>
    </div>
  );
};

export default ServoMotor;
