import React from "react";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import pcb from "../../../images/pcb/jack-a2s-pcb.jpg";

const JackA2sPcb = () => {
  return (
    <div>
      <ProductPage
        image={pcb}
        alt="jack A2s pcb board"
        img_title="Jack A2s PCB"
        title="Jack A2s Fully Functional UBT PCB Board"
        price={4299}
        del_price={6000}
        discount={28}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Original Product"
        box_content="PCB Board"
        Highlight_1="Brand new PCB Board"
        Highlight_2="Fully Running PCB with all specs"
        Highlight_3="Imported PCB Board"
        Highlight_4="Jack Original"
        key_features="Jack A2s Direct Drive Sewing Machine Original PCB Board.
    "
      />
      <Specs
        brand_name="Jack"
        model_name="A-2s"
        color="Green"
        type="PCB Board"
        material="-"
        speed="-"
        shape="-"
      />
    </div>
  );
};

export default JackA2sPcb;
