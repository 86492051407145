import React from "react";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import pcb from "../../../images/pcb/jack-9100ba.jpg";

const Jack9100BA = () => {
  return (
    <div>
      <ProductPage
        image={pcb}
        alt="jack 9100b (Old Model) pcb board"
        img_title="Jack 9100b (Old Model) PCB"
        title="Jack 9100b (Old Model) Direct Drive Overlock PCB Board"
        price={3899}
        del_price={8000}
        discount={42}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Original Product"
        box_content="PCB Board"
        Highlight_1="Brand new PCB Board"
        Highlight_2="Fully Running PCB with all specs"
        Highlight_3="Imported PCB Board"
        Highlight_4="Jack Original"
        key_features="Jack 9100b (Old Model) Direct Drive Overlock Sewing Machine Original PCB Board.
    "
      />
      <Specs
        brand_name="Jack"
        model_name="9100b old model"
        color="Green"
        type="PCB Board"
        material="-"
        speed="-"
        shape="-"
      />
    </div>
  );
};

export default Jack9100BA;
