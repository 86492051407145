import React from 'react'
import "../styles/CategorySpare.css"

const CategorySpare = ({image,title,alt,img_title}) => {
  return (
    <div className='category-spare'>
    <img className='cat-image' src={image} alt={alt} title={img_title} />
    <h1 className='cat-title'>{title}</h1>
    </div>
  )
}

export default CategorySpare