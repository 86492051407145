import React from "react";
import { Link } from "react-router-dom";
import Product from "../../components/Product";
import "../../styles/categories/SparePartsCategory.css";

// images
import groz from "../../images/spare parts/single-needle/groz-db1.jpg";
import flying_tiger from "../../images/spare parts/single-needle/flying-tiger-db1.jpg";
import white_crane from "../../images/spare parts/single-needle/white-crane-db1.jpg";
import organ from "../../images/spare parts/single-needle/organ-db1.jpg";
import schmetz_dc1 from "../../images/needle/schmetz-dc1.jpg"
import pony from "../../images/needle/pony-needles.jpg"


const NeedlesCat = () => {
  return (
    <>
      <h4 className="sub-title">Lockstitch Sewing Machine Parts</h4>
      <div className="spare-parts-categories">
        <Link
          to="/groz-beckert-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={groz}
            title="Groz-Beckert Needle DBX1"
            price={55}
            del_price={80}
            off={48}
            alt="groz beckart needle"
            img_title="Groz Beckart"
          />
        </Link>
        <Link
          to="/flying-tiger-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={flying_tiger}
            title="Flying Tiger Needle DBX1"
            price={15}
            del_price={20}
            off={35}
            alt="flying tiger needle"
            img_title="Flying Tiger Needle"
          />
        </Link>
        <Link
          to="/white-crane-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={white_crane}
            title="White Crane Needle DBX1"
            price={22}
            del_price={30}
            off={35}
            alt="white crane needle"
            img_title="White Crane Needle"
          />
        </Link>
        <Link
          to="/organ-needle-db1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={organ}
            title="Organ Needle DBX1"
            price={75}
            del_price={80}
            off={20}
            alt="organ needle"
            img_title="Organ Needle"
          />
        </Link>
        <Link
          to="/schmetz-needle-dc1"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={schmetz_dc1}
            title="Schmetz DCx1"
            price={55}
            del_price={80}
            off={32}
            alt="schmetz needle"
            img_title="Schmetz Needle"
          />
        </Link>
        <Link
          to="/pony-needle"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={pony}
            title="Pony Turpai Needle"
            price={22}
            del_price={25}
            off={13}
            alt="pony needle"
            img_title="Pony Needle"
          />
        </Link>
      </div>
    </>
  );
};

export default NeedlesCat;
