import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import hinges from "../../../../images/spare parts/single-needle/hinjes.jpg";

const HingesSetSN = () => {
  return (
    <div>
      <ProductPage
        image={hinges}
        alt="hinges set for sewing machine"
        title="Hinges Set for Lockstitch Machine"
        price={20}
        del_price={40}
        discount={43}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Tension Set"
        Highlight_1="High Quality Material"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Hinges Set for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Tension"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Material"
      />
    </div>
  );
};

export default HingesSetSN;
