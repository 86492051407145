import React from "react";
import "../styles/Product.css";

const Product = ({ image, title, price, del_price, off, alt, img_title }) => {
  return (
    <>
      <div className="product">
        <img className="image" src={image} alt={alt} title={img_title} />
        <h1 className="Title">{title}</h1>
        <h2 className="price">
          ₹{price}
          <del>₹{del_price}</del> <strong>{off}% off</strong>
        </h2>
      </div>
    </>
  );
};

export default Product;
