import React from 'react'
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import kundi_cover from "../../../../images/spare parts/single-needle/kundi-cover.jpg";

const KundiCoverSN = () => {
  return (
    <div>
    <ProductPage
      image={kundi_cover}
      alt="sewing machine tension"
      title="Kundi for Lockstitch Machine"
      price={15}
      del_price={45}
      discount={43}
      ruppee_icon="Cash On Delivery"
      delivery_icon="Fast delivery"
      trust_icon="Trusted Product"
      box_content="Kundi Cover"
      Highlight_1="High Quality Steel"
      Highlight_2="Great for Industrial Use"
      Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
      Highlight_4="Imported Product"
      key_features="Kundi Cover for Industrial Lockstitch Single Needle Sewing Machine"
    />
    <SpecsSpares
      brand_name="Generic"
      type="Kundi Cover"
      compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
      material="High Quality Steel"
    />
  </div>
  )
}

export default KundiCoverSN