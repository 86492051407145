import React from "react";
import { Link } from "react-router-dom";
import "../styles/NavbarDesk.css";

const NavbarDesk = () => {
  return (
    <div className="nav-desk">
      <Link to="/" style={{ textDecoration: "none", color: "black" }}>
        <h1 className="nav-desk-logo">Sewix</h1>
      </Link>
      <nav>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          <h3 className="nav-desk-h3">Home</h3>
        </Link>
        <Link to="/products" style={{ textDecoration: "none", color: "black" }}>
          <h3 className="nav-desk-h3">Products</h3>
        </Link>
        <Link to="/contact" style={{ textDecoration: "none", color: "black" }}>
          <h3 className="nav-desk-h3">Contact</h3>
        </Link>
        <Link to="/about" style={{ textDecoration: "none", color: "black" }}>
          <h3 className="nav-desk-h3">About</h3>
        </Link>
      </nav>
    </div>
  );
};

export default NavbarDesk;
