import React from 'react'
import Product from '../../components/Product'

// Images 
import silti from "../../images/silti-press.jpg"

const SewingMachineAccessories = () => {
  return (
    <div><Product 
    image={silti}
    title="Silti ST-96 Steam Press"
    price={2699}
    del_price={3500}
    off={27} /></div>
  )
}

export default SewingMachineAccessories