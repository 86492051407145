import React from "react";
import { Link } from "react-router-dom";
import Product from "../../components/Product";


// Images 
import bobbin_case_781 from '../../images/spare parts/juki-781/bobbin-case.jpg'


const BobbinCasesCat = () => {
  return (
    <div className="spare-parts-categories">
      <Link
          to="/juki-butttonhole-bobbin-case"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Product
            image={bobbin_case_781}
            title="Haya Bobbin Case"
            price={350}
            del_price={400}
            off={23}
            alt="haya bobbin case"
            img_title="Haya Bobbin Case"
          />
        </Link>
    </div>
  );
};

export default BobbinCasesCat;
