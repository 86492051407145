import React from "react";
import "../styles/HeroDesk.css";
import juki from "../images/heropage.jpg";
import hero_mobile from "../images/heropage-mobile.jpg";

const HeroDesk = () => {
  return (
    <div className="hero-desk">
      {/* <h1>
        All Your Sewing <br />
        Needs At Your <br />
        Doorstep! <br />
      </h1> */}
      {/* <button>Hello</button> */}
      <div className="hero-mobile">
        <img src={hero_mobile} alt="" />
      </div>
      <div className="hero-desktop">
        <img
          src={juki}
          alt="juki sewing machine"
          // title="Sewix-For All Your Sewing Needs"
        />
      </div>
    </div>
  );
};

export default HeroDesk;
