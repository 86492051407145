import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";

import schmetz from "../../../../images/needle/schmetz-dc1.jpg"

const SchmetzDC1 = () => {
  return (
    <div>
      <ProductPage
        image={schmetz}
        alt="schmetz needle"
        img_title="Schmetz DCx1 Needle"
        title="Schmetz DCx1 Needle"
        price={55}
        del_price={80}
        discount={24}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Original Product"
        box_content="Needle Packet"
        Highlight_1="Original Product"
        Highlight_2="High Quality Material"
        Highlight_3="DC1 size - 14,11"
        Highlight_4="Industrial Use"
        key_features="Schmetz Needle For Industial Sewing Machine. Size DCx1 no. 14 ideal for Lockstitch Sewing machine"
      />
      <SpecsSpares
        brand_name="Schmetz"
        compatibility="DC1"
        type="Needle"
        material="Steel"
        />
    </div>
  );
};

export default SchmetzDC1;
