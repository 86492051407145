import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import scissor from "../../../../images/spare parts/juki-781/scissor.jpg";

const Scissor781 = () => {
  return (
    <div>
      <ProductPage
        image={scissor}
        alt="juki buttonhole scissor"
        title="Juki Buttonhole Scissor Set"
        price={250}
        del_price={350}
        discount={28}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Scissor Set"
        Highlight_1="High Quality Steel"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit with Juki LBH-781, LBH-780, LBH-772"
        Highlight_4="Imported Product"
        key_features="Scissor Set For Juki ButtonHole Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Scissor"
        compatibility="Fit with Juki LBH-781, LBH-780, LBH-772"
        material="High Quality Steel"
      />
    </div>
  );
};

export default Scissor781;
