import React from "react";
import SEO from "../../components/SEO";
import "../../styles/categories/Categories.css"
import { Link } from "react-router-dom";
import ProductCategory from "../../components/ProductCategory";

// images 
// import u1_usha from "../../images/Usha-hand/usha1.jpg";
import servo from "../../images/servo.jpg";
import s1_spare from "../../images/shuttle.jpeg";
import press from "../../images/silti-press.jpg";

const Categories = () => {
  return (
    <div className="categories">
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
      <SEO
      title="Buy Sewing Machine Parts and Accessories Online - Sewix.in"
      description="Sewix.in: Buy sewing machine, jack f4, servo motor, steam press, cloth cutting machine, pcb board, folders, scissors, juki parts, gauge set, hook set. Home delivery & Cash on Delivery Available."
      name="Sewix"
      type='website'
      url="https://www.sewix.in/Categories" 
      main_image={servo}
      keywords="Sewix.in, Sewix, sewing machine parts, sewing machine motor, steam press, steam iron, cloth cutting machine, juki parts, JUKI Parts, JACK Parts, BROTHER Parts, PEGASUS Parts, KANSAI SPECIAL Parts, SIRUBA Parts, YAMATO Parts" />
      <Link
        to="/domestic-sewing-machine"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ProductCategory
          // image={u1_usha}
          title="Sewing Machines"
          alt="usha sewing machine"
          img_title="Domestic Sewing Machine"
        />
      </Link>
      <Link
        to="/sewing-machine-motor"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ProductCategory
          image={servo}
          title="Servo Motors"
          alt="sewing machine motor"
          img_title="Power Saving Motor"
        />
      </Link>
      <Link
        to="/sewing-machine-parts"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ProductCategory
          image={s1_spare}
          title="Parts"
          alt="sewing machine shuttle"
          img_title="Sewing Machine Parts"
        />
      </Link>
      <Link
        to="/sewing-machine-accessories"
        style={{ textDecoration: "none", color: "black" }}
      >
        <ProductCategory
          image={press}
          title="Accessories"
          alt="steam press"
          img_title="Sewing Machine Accessories"
        />
      </Link>
    </div>
  );
};

export default Categories;






