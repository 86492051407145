import React from "react";
import "../styles/ProductPageAll.css";

import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SecurityIcon from "@material-ui/icons/Security";

const ProductPage = ({
  image,
  title,
  price,
  del_price,
  discount,
  ruppee_icon,
  delivery_icon,
  trust_icon,
  box_content,
  Highlight_1,
  Highlight_2,
  Highlight_3,
  Highlight_4,
  key_features,
  alt,
  img_title,
  specs,
}) => {
  return (
    <>
      <div className="product-page">
        <div className="product-page-desk">
          <div>
            <img className="image-desk" src={image} alt={alt} title={img_title} />
          </div>
          <div className="product-page-desk-info">
            <h1 className="main-title">{title}</h1>
            <span className="pricing">
              <h2 className="amount">Price :₹{price}</h2>
              <h2 className="del-amount">
                <del>{del_price}</del>
              </h2>
              <h2 className="discount">{discount}% off</h2>
            </span>
            <div>
              <h4>To Buy:</h4>
              <a href="tel: +91 9354237363" className="buy">
                Call:+91 9354237363
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=919354237363"
                className="buy"
              >
                Whatsapp: +91 9354237363
              </a>
            </div>
            <div className="icons">
              <h5>
                <MonetizationOnRoundedIcon /> <br />
                {ruppee_icon}
              </h5>
              <h5>
                <LocalShippingIcon /> <br />
                {delivery_icon}
              </h5>
              <h5>
                <SecurityIcon /> <br />
                {trust_icon}
              </h5>
            </div>
          </div>
        </div>
        <main>
          <div className="contents">
            <h4>Contents In The Box:</h4>
            <p>{box_content}</p>
          </div>
          <div>
            <h1 className="highlights-h1">Highlights:</h1>
            <ul>
              <li><h1 className="highlights-h1">{Highlight_1}</h1></li>
              <li><h1 className="highlights-h1">{Highlight_2}</h1></li>
              <li><h1 className="highlights-h1">{Highlight_3}</h1></li>
              <li><h1 className="highlights-h1">{Highlight_4}</h1></li>
              
            </ul>
          </div>
          <div className="key-features">
            <h4>Key Features:</h4>
            <p>{key_features}</p>
          </div>
          
        </main>
      </div>
    </>
  );
};

export default ProductPage;
