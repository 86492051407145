import React from "react";
import SEO from "../../../components/SEO";

import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import dtech from "../../../images/cutting-machine/dtech.jpg"

const Dtech110 = () => {
  return (
    <div>
      {/* SEO props are title, description, name, type, url, main_image, keywords */}
<SEO
title="Dtech 110 Cloth Cutting Machine"
description="Dtech 110 CLOTH CUTTING MACHINE. This heavy-duty electric Single Phase (220V/50 Hz) (250 W) rotary cutter will cut up to 15 layers of fabric. Grinding Wheel is attached next to the blade and the blade can easily be sharpened with a press of a button. ( Refer to the instruction manual for more info.). It can cut cotton, woolen, lines, silk, chemical fiber, etc. Freely cutting, stable running, easily used.Included Components - Cutting machine, power-cord.It is a high-performance circular knife cutting machine aimed for a super fine cut from a single piece to the wide variety of works."
name="Sewix"
type="website"
url="https://www.sewix.in/dtech-110mm-cloth-cutting-machine"
main_image={dtech}
keywords="Breostar cutting machine, breostar cutting machine, dtech cutting machine ,cupid cutting machine ,sewix ,fabric cutter	,rotary fabric cutter	,fabric cutting machine	,laser fabric cutter	,fabric cutter roller	,electric fabric cutter	,fabric laser cutting machine	,circular fabric cutter	,best rotary fabric cutter	,electric rotary fabric cutter	,cloth cutter	,industrial fabric cutting machine	,cloth cutting machine	,industrial fabric cutter	,best fabric cutting machines " />
      <ProductPage
        image={dtech}
        alt="Dtech 110 Cloth Cutting Machine"
        img_title="Dtech 110 Cloth Cutting Machine"
        title="Dtech 110 Cloth Cutting Machine"
        price={4999}
        del_price={6000}
        discount={32}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Cutting Machine, 2 spring"
        Highlight_1="Heavy duty"
        Highlight_2="Full metal body"
        Highlight_3="Heavy Duty"
        Highlight_4="Excellent Quality"
        key_features="Dtech 110 CLOTH CUTTING MACHINE. This heavy-duty electric Single Phase (220V/50 Hz) (250 W) rotary cutter will cut up to 15 layers of fabric. Grinding Wheel is attached next to the blade and the blade can easily be sharpened with a press of a button. ( Refer to the instruction manual for more info.). It can cut cotton, woolen, lines, silk, chemical fiber, etc. Freely cutting, stable running, easily used.Included Components - Cutting machine, power-cord.It is a high-performance circular knife cutting machine aimed for a super fine cut from a single piece to the wide variety of works. ."
      />
      <Specs brand_name="Dtech"
      model_name="110mm"
      color="Silver & Blue"
      type="Heavy Duty"
      material="Raw Metal"
      speed="-"
      shape="Round"
       />
    </div>
  );
};

export default Dtech110;
