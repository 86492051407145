import React from "react";
import '../styles/WhatsappIcon.css';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";


const WhatsappIcon = () => {
  return (
    <div className="whatsapp">
        <a href="https://api.whatsapp.com/send?phone=919354237363"><WhatsAppIcon fontSize="large" className="whatsapp-icon"/></a>
      
    </div>
  );
};

export default WhatsappIcon;
