import React from "react";
import ProductPage from "../../../components/ProductPage";
import Specs from "../../../components/Specs";

import hmc from "../../../images/servo-motor/hmc-motor.jpg";

const HmcBudget = () => {
  return (
    <div>
      <ProductPage
        image={hmc}
        alt="hmc servo motor"
        img_title="HMC Servo Motor"
        title="HMC Original Power Saving Servo Motor"
        price={3099}
        del_price={4500}
        discount={28}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="1 Year Warranty"
        box_content="Motor, Board, Fittings, Connecting Rod, User Manual"
        Highlight_1="Power Saves upto 75%"
        Highlight_2="Aluminium Body"
        Highlight_3="Speed Controller"
        Highlight_4="LED Display"
        key_features="HMC - A famous brand of servo sewing machine motor. A good option to change your traditional sewing machine motor, can replace your old sewing machne motor into esda sewing machine motor. HMC Safe your power & reduce your electricity due to its steping rotation technology because there is no electro magnetic based technology. There is multiple speed 2800 rpm to 5000 rpm. can change your rotationn side according to your need can reduse your electrycity bill amount at least 60 - 75%. You will also get 1 Year of off-side warranty."
      />
      <Specs
        brand_name="HMC"
        model_name="Servo"
        color="Grey"
        type="Power Saving"
        material="Aluminium"
        speed="5000"
        shape="Round"
      />
    </div>
  );
};

export default HmcBudget;
