import React from "react";
import ProductPage from "../../../../components/ProductPage";
import SpecsSpares from "../../../../components/SpecsSpares";
import oil_seal from "../../../../images/spare parts/single-needle/oil-seal.jpg";

const OilSealSN = () => {
  return (
    <div>
      <ProductPage
        image={oil_seal}
        alt="sewing machine oil seal"
        title="Oil Seal for Lockstitch Machine"
        price={25}
        del_price={45}
        discount={43}
        ruppee_icon="Cash On Delivery"
        delivery_icon="Fast delivery"
        trust_icon="Trusted Product"
        box_content="Oil Seal"
        Highlight_1="High Quality Materail"
        Highlight_2="Great for Industrial Use"
        Highlight_3="Fit With Every Brand's Lockstitch Single Needle Machine"
        Highlight_4="Imported Product"
        key_features="Oil Seal for Industrial Lockstitch Single Needle Sewing Machine"
      />
      <SpecsSpares
        brand_name="Generic"
        type="Tension"
        compatibility="Jack, Juki, any kind of Lockstich Single Needle Sewing Machine"
        material="High Quality Material"
      />
    </div>
  );
};

export default OilSealSN;
